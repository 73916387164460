import React, { useEffect, useState, useCallback } from "react";
import PageHeader from "../../../../../atoms/PageHeader";
import Search from "../../../../../molecules/search";
import DateRangePickerShortCuts from "../../../../../organisms/dateRangePicker";
import NoContentCard from "../../../../../atoms/NoContentCard";
import NoContentIcon from "../../../../../icons/NocontentIcon";
import Pagination from "../../../../../molecules/Pagination";
import DetailCard from "../../../activities/DetailCard";
import { useCookies } from "react-cookie";
import { getData } from "../../../../../../services/index";
import { startOfMonth, endOfMonth,addHours,startOfDay} from "date-fns";

const ActivityLogs = () => {

  const defaultToday = new Date();

  const [cookies] = useCookies(["bid", "b_t"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [date, setDate] = useState({
    start_date: addHours(startOfDay(startOfMonth(defaultToday)),12),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)),12),
  });

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "ActivityLogs/getActivityLogs",
        params: {
          type: "Business",
          business_id: cookies.bid,
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
        },
      });

      setTableData(res);
    })();
  }, [searchedValue, cookies.b_t,currentPage, itemsPerPage]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <div className="d-flex space-between">
        <PageHeader title="Activity Logs" type="small" removeHeader={true} />
        <div className="d-flex gap-3 align-items-center">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
            placeholder="Search By Action"
          />
          <DateRangePickerShortCuts setDate={setDate}/>
        </div>
      </div>

      <>
        <div className="activities">
          {tableData?.data?.length > 0 ? (
            tableData.data.map((item) => (
              <DetailCard key={item.id} data={item} />
            ))
          ) : (
            <NoContentCard
              title="No Log Found"
              type="Company"
              icon={<NoContentIcon />}
              small={true}
            />
          )}
        </div>

        {tableData?.total_record > 25 && (
          <div className="mt-3">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={tableData?.total_record}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onItemsPerPageChange={handleItemsPerPageChange}
            />
          </div>
        )}
      </>
    </>
  );
};

export default ActivityLogs;
