import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { getVenueList } from "../../../../../services/basicGets";
import { postFormData } from "../../../../../services";
import SelectDropdown from "../../../../molecules/Dropdown";
import Modal from "../../../../organisms/modals/Modal";
import { useCookies } from "react-cookie";
import CustomDropzone from "../../../../molecules/Dropzone";
import Download from "../../../../icons/Download";
import File from "../../../../../assets/Demo.xlsx";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";

const Import = ({ isActive, onClose }) => {
  const [cookies] = useCookies(["b_t", "bid"]);
  const [venueList, setVenueList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    (async () => {
      // Your async code here
      const res = await getVenueList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setVenueList(res);
    })();
  },[]);

  const initialValues = {
    venue_id: null,
    file: null,
  };

  const validationSchema = yup.object().shape({
    venue_id: yup.string().required("Venue Name is required"),
    file: yup.string().required("File Selection is Required"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);
    try {
      const res = await postFormData({
        endpoint: "Employe/importEmployee",
        params: { venue_id: values.venue_id },
        data: {excel : values.file},
        token: cookies.b_t,
      });

      if(res){
        toast.success('Employees Imported Successfully',{duration : 1000 , style : confimationStyles })
        onClose()
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title="Import Employees"
      width="550px"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          dirty,
          isValid,
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleBlur,
        }) => (
          <>
            <div className="form-group">
              <label htmlFor="">Select Venue</label>

              <SelectDropdown
                placeholder="Select Venue"
                data={venueList}
                onChange={(option) => {
                  setFieldValue("venue_id", option);
                }}
                selectedValue={values?.venue_id}
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="">Select File</label>
              <CustomDropzone
                onFileSelect={(file) => {
                  setFieldValue("file", file);
                }}
                selectedFile={initialValues?.file}
                adType="Excel"
              />
              <div className="btn-link btn-link-primary mt-1">
                <a
                  href="https://visitorxsys-prod.s3.ap-south-1.amazonaws.com/SampleFiles/EmployeeImportDemo.xlsx"
                  download
                  style={{ color: "#6B66DA" }}
                >
                  <Download />
                  Download Demo File
                </a>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-primary w-30"
                type="submit"
                disabled={!isValid || !dirty || isSaving}
                onClick={() => handleSubmit(values)}
              >
                Import
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default Import;
