import React from 'react'
import CircleAvatar from "../../atoms/CircleAvatar";

const ImageAndInitialViewer = ({image,name}) => {
  return (
    <div>
    {image ? (
      <img
        style={{ borderRadius: "25px" }}
        src={image}
        width={48}
        height={48}
        loading="lazy"
        alt='initial'
      />
    ) : (
      <CircleAvatar
        color="#f4f4f4"
        name={name}
        size={48}
        fontSize="15px"
        border="1px solid #efefef"
        fontColor="#6B66DA"
        iconColor="#6B66DA"
      />
    )}
  </div>
  )
}

export default ImageAndInitialViewer