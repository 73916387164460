import { useCookies } from "react-cookie";
import CloseButton from "./CloseButton";
import CustomButton from "../../atoms/CustomButton";
import './styles.scss'


const Modal = ({ width = "400px", onClose, isActive }) => {
  const [, , removeCookies] = useCookies(["b_t", "bun", "buid","mode",'br','isa','profile','bid','role','va','both']);
  const cookieNames = ["b_t", "bun", "buid",'mode','br','isa','profile','bid','role','va','both'];
  
  const handleLogout = () => {
    cookieNames.forEach((cookieName) => {
      removeCookies(cookieName, { path: "/" });
    });
  };
  const styles = {
    width: width,
  };
  return (
    <div className={`modal ${isActive ? "active" : ""}`}>
      <div className="modal-content" style={styles}>
        <div className="modal-body py-4">
          <CloseButton onClose={() => onClose()} />
          <p className="modal-heading mb-0 mt-2 mb-2">Logout</p>
          <p className="modal-description">
            Are you sure do you want to Logout.
          </p>
          <div className="d-flex w-100 mt-3">
            <CustomButton
              text="No"
              handleClick={onClose}
              style={{ flex: "1 0 45%" }}
              iconRequired={false}
            />
            {/* Adjust the style for the "Confirm" button */}
            <button
              className="btn btn-primary mt-0"
              onClick={() => handleLogout()}
              style={{ flex: "1 0 45%", marginLeft: "10px" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
