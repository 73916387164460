import moment from "moment";

export const  convertUtcToLocalTime = (utcTimeString) => {
    // Get the current date
    const currentDate = moment().format('YYYY-MM-DD');

    // Create a full datetime string assuming the check-in time is in UTC
    const fullUtcTimeString = `${currentDate}T${utcTimeString}Z`;

    // Convert UTC to local time
    const localTime = moment.utc(fullUtcTimeString).local().format('hh:mm A');

    return localTime;
}