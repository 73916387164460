
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthenticated } from '../hooks/useAuthenticated';
import { useCookies } from 'react-cookie';

const GuestGuard = ({ children }) => {
  const [cookies] = useCookies(['mode'])
  const auth = useAuthenticated();

  const mode = atob(cookies.mode || '')

  if (auth) {

    return mode === 'Employee' ? <Navigate to='/dashboard/employee' /> : <Navigate to='/dashboard/business'/>;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;