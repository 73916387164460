import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./ScrollToTop";
import "./assets/globalStyles/styles.scss";
import { useCookies } from "react-cookie";

const App = () => {
  const content = useRoutes(routes);
  return (
    <>
      <ScrollToTop />
      {content}
      <Toaster />
    </>
  );
};

export default App;
