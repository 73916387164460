import React, { useEffect, useState } from "react";
import PageCard from "../../../../atoms/PageCard";
import SelectDropdown from "../../../../molecules/Dropdown";
import VisitorChart from "./VisitoeChart";
import DateRangePickerShortCuts from "../../../../organisms/dateRangePicker";
import addMonths from "date-fns/addMonths";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import { startOfMonth, endOfMonth, addDays ,addHours,startOfDay} from "date-fns";
import dayjs from "dayjs";

const VisitorChartContainer = ({ venueData }) => {
  const [cookies] = useCookies(["b_t", "bid"]);

  const defaultToday = new Date();
  const screenWidth = useScreenWidth();
  const [selectedVenue, setSelectedVenue] = useState("All");

  useEffect(() => {
    if (venueData?.length === 1) {
      setSelectedVenue(venueData?.[0]?.value);
    }
  }, [venueData]);
  const [date, setDate] = useState({
    start_date: addHours(startOfDay(startOfMonth(defaultToday)),12),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)),12),
  });
  // console.log('test', addDays(date?.start_date , 1).toISOString())

  const [graphData, setGraphData] = useState();

  const handleGraphUpdation = async () => {
    const res = await getData({
      endpoint: "BusinessDashboard/getVisitorAnalytics",
      params: {
        business_id: cookies.bid,
        venues:
          selectedVenue === "All" ? null : JSON.stringify([selectedVenue]),
        start_date: date?.start_date,
        end_date: date?.end_date,
      },
      token: cookies.b_t,
    });

    if (res) {
      setGraphData(res?.counts);
    }
  };

  useEffect(() => {
    handleGraphUpdation();
  }, []);

  return (
    <>
      <style>
        {`
      @media (min-width: 500px) and (max-width: 1230px) {
            .pagecard-responsive-tab {
              padding: 1rem;
            }
            .rmove-heder-margin{
            width:100%;
            h3{
            margin-bottom:0 !important;
            }
            }

            .drop-items-hone-tab {
              width: 100%;
              justify-content: space-between;
            }
              .rs-picker-daterange-panel{
              }
          }
        `}
      </style>
      <PageCard classes={"pagecard-responsive-tab"}>
        <div>
          <div className="d-flex justify-content-between align-items-center mb-4 flex-wrap">
            <div className="d-flex justify-content-between align-items-center mb-2 rmove-heder-margin">
              <h3 className="h2-i mb-4">Visitor Analytics</h3>
              <button
                className="btn btn-primary tab-employee-responsive-block"
                style={{ height: "46px" }}
                onClick={() => handleGraphUpdation()}
              >
                Update Graph
              </button>
            </div>
            <div className="d-flex gap-3 drop-items-hone-tab">
              <SelectDropdown
                type="dashboard"
                placeholder="Select Venue"
                data={venueData}
                selectedValue={selectedVenue}
                onChange={(option) => setSelectedVenue(option)}
              />
              <DateRangePickerShortCuts setDate={setDate} />
              <button
                className="btn btn-primary tab-employee-responsive-none"
                style={{ height: "46px" }}
                onClick={() => handleGraphUpdation()}
              >
                Update Graph
              </button>
            </div>
          </div>

          <VisitorChart data={graphData} />
        </div>
      </PageCard>
    </>
  );
};

export default VisitorChartContainer;
