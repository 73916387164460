import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconCircle from "../../atoms/avatars/IconCircle";
import styles from "./style.module.scss";
import Modal from "../../organisms/modals/Modal";
import "./_info-card.scss";
const InfoCard = ({
  icon,
  svgIcon,
  isSvg,
  color,
  title,
  data,
  unit,
  link,
  style,
  dotIcon,
  children,
  modalActive,
  modalActiveState
}) => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState({
    modalId: "",
    modalTitle: title,
    isModalOpen: false,
    formData: null,
  });

  useEffect(() => {
    if (modalActive !== null) {
      closeModal();
    }
  }, [modalActive]);

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, isModalOpen: false }));
  };

  useEffect(() => {}, []);
  const onClickHandle = (e) => {
    e.preventDefault();
    if (link) navigate(link);
    setModalState({
      modalTitle: "Products",
      isModalOpen: true,
    });
    modalActiveState(null)
  };

  return (
    <>
      <div
        className="info-card cursor-pointer"
        onClick={onClickHandle}
        style={style}
      >
        <div>
          {isSvg ? (
            <IconCircle
              svgIcon={svgIcon}
              color={color}
              size="56px"
              iconSize="24px"
              isSvg={true}
            />
          ) : (
            <IconCircle icon={icon} color={color} size="56px" iconSize="24px" />
          )}
        </div>
        <div className="info-card-detail" style={{ marginLeft: "14px" }}>
          <span
            className="text-dark heading"
            style={{ fontSize: "24px", fontWeight: "600" }}
          >
            {data}&nbsp;<span className="p-lg">{unit ? unit : ""}</span>
          </span>
          <span className="view-muted fw-bold" style={{ fontSize: "14px" }}>
            {title}
          </span>
        </div>
        {dotIcon && (
          <div
            className={styles.dotIconWrap}
            onClick={() => {
              setModalState({
                isModalOpen: true,
              });
            }}
          >
            <i className="fi fi-br-menu-dots-vertical"></i>
          </div>
        )}
      </div>
      <Modal
        isActive={modalState.isModalOpen}
        onClose={closeModal}
        title={title}
        width="600px"
      >
        {children}
      </Modal>
    </>
  );
};

export default InfoCard;
