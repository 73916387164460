import React, { useEffect, useState , useRef} from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import PageHeader from "../../../../atoms/PageHeader";
import BackBtn from "../../../../atoms/BackBtn";
import * as yup from "yup";
import PageCard from "../../../../atoms/PageCard";

import InputLayout from "../../../../atoms/InputLayout";
import SelectDropdown from "../../../../molecules/Dropdown";
import { countryCodeData } from "../../../../../helpers/countryCodes";

import Error from "../../../../atoms/Error";
import { DateSelector } from "../../../../organisms/MonthYearSelector";
import CustomTimePicker from "../../../../organisms/TimePicker";
import QRCode from "qrcode";
import {
  getDeviceList,
  getEmployeeList,
  getEmployeeVenueList,
  getInviteeTypeList,
  getVenueList,
} from "../../../../../services/basicGets";
import CustomButton from "../../../../atoms/CustomButton";
import { useFormikContext } from "formik";

import {
  getData,
  patchData,
  patchFormData,
  postData,
  postFormData,
} from "../../../../../services";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { generateRandomString } from "../../../../../helpers/generateString";

import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import { json, useNavigate, useParams } from "react-router-dom";

import DeviceDataSElection from "./DeviceDataSelection";
import { base64ToFile } from "../../../../../helpers/base64ToFile";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { MODE_EMPLOYEE } from "../../../../../helpers/constants";

dayjs.extend(utc);

const Add = ({ type = "add", mode }) => {
  // const test = useFormikContext();
  const formikRef = useRef();
  // console.log('test',test)
  const { id } = useParams();
  const [cookies] = useCookies(["b_t", "bid", "buid"]);
  const navigate = useNavigate();
  const [venueData, setVenueData] = useState([]);
  const [inviteeType, setInviteeType] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [empData, setEmpData] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [current, setCurrent] = useState();

  useEffect(() => {
    setCurrent(dayjs().utc().format("HH:mm:ss"));
  }, []);

  const isNotVenueAdmin = (venue_id) => {
    const foundVenue = venueData.find((venue) => venue.value === venue_id);
    return foundVenue?.venue_admin;
  };

  const fetchEmployeeData = async (venue, setFieldValue) => {
    const empRes = await getEmployeeList({
      business_id: cookies.bid,
      token: cookies.b_t,
      venue_id: venue,
    });

    console.log('emp-res',empRes)
    setFieldValue("venue_id", venue);
    const isFound = isNotVenueAdmin(venue);

    if (!isFound && mode === MODE_EMPLOYEE) {
      setFieldValue("emp_id", cookies.buid);
    }
    setEmpData(empRes?.data);
  };

  useEffect(() => {
    (async () => {
      if (mode === MODE_EMPLOYEE) {
        const res = await getEmployeeVenueList({
          emp_id: cookies.buid,
          token: cookies.b_t,
        });
        setVenueData(res);
      } else {
        const res = await getVenueList({
          business_id: cookies.bid,
          token: cookies.b_t,
        });
        setVenueData(res);
      }

      const inviteeTypeRes = await getInviteeTypeList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setInviteeType(inviteeTypeRes);

      if (type === "edit") {
        const empRes = await getEmployeeList({
          business_id: cookies.bid,
          token: cookies.b_t,
          venue_id: initialValues?.venue_id,
        });
        setEmpData(empRes?.data);
      }

      const deviceRes = await getDeviceList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setDeviceList(deviceRes?.data);
    })();

    const fetchData = async () => {
      const res = await getData({
        endpoint: "Invite/getInviteDetailsById",
        token: cookies.b_t,
        params: { id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  const initialData = {
    venue_id: initialValues?.venue_id || (mode==='Employee' && venueData?.length === 1) ? venueData?.[0]?.value :  null,
    emp_id: initialValues?.emp_id || null,
    invitee_type: initialValues?.invitee_type || null,
    invitee_name: initialValues?.invitee_name || null,
    email: initialValues?.email || null,
    phone_number: initialValues.phone_number || null,
    country_code: "+91" || initialValues.country_code,
    start_time: initialValues?.start_time || current,
    end_time: initialValues?.end_time || null,
    purpose: initialValues?.purpose || null,
    is_reusable_code: initialValues?.is_reusable_code || null,
    code_start_date: initialValues?.code_start_date || null,
    code_end_date: initialValues?.code_end_date || null,
    company: initialValues?.company || null,
    address: initialValues?.address || null,
    date: initialValues?.date || null,
    device: initialValues?.device || null,
    barcode_string: initialValues?.bar_code || null,
    remove_visitors: [],
    visitors: (initialValues?.addition_visitor || [])?.map((visitor) => {
      return {
        id: visitor?.id,
        name: visitor?.visitor_name,
        email: visitor?.email,
        country_code: "+91" || visitor?.country_code,
        phone_number: visitor?.phone_number || null,
      };
    }),
  };

  useEffect(() => {
    (async () => {
      if(mode==='Employee' && venueData?.length === 1){
        // console.log('check --> ')
      fetchEmployeeData(venueData?.[0]?.value, formikRef.current.setFieldValue)
      }
    })()
  },[venueData])

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getUTCHours().toString().padStart(2, "0");
    const minutes = now.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // Function to get current date in "YYYY-MM-DD" format
  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const validationSchema = yup
    .object()
    .shape({
      venue_id: yup.string().required("Venue Name is required"),
      emp_id: yup.string().required("Employee Selection is required"),
      invitee_type: yup.string().required("Invitee Type is required"),
      invitee_name: yup.string().required("Invitee Name is required"),
      email: yup.string().required("Email is required").email("Invalid email"),
      country_code: yup.string().required("Country code is required"),
      phone_number: yup.string().when("country_code", {
        is: "+91", // Condition: when country_code is '+91'
        then: () =>
          yup
            .string()
            .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
            .nullable()
            .required("Primary Phone Number is required"),
        otherwise: () =>
          yup
            .string()
            .matches(/^[0-9]{6,15}$/, "Please Enter a valid Phone Number")
            .nullable()
            .required("Primary Phone Number is required"),
      }),
      date: yup.date().required(" Date is required"),
      purpose: yup.string().required("Purpose is required"),
      company: yup.string().required("Invitee Name is required"),
      address: yup.string().required("Address is required"),
      start_time: yup
        .string()
        .required("Start Time is Required")
        .test(
          "is-greater-than-current-time-if-today",
          "Start Time must be greater than the current time if the Meeting date is current date",
          function (value) {
            const { date } = this.parent;
            if (
              date &&
              new Date(date).toDateString() === new Date().toDateString()
            ) {
              const currentTime = getCurrentTime();
              return value > currentTime;
            }
            return true;
          }
        ),
      end_time: yup
        .string()
        .required("End Time is Required")
        .test(
          "is-greater-than-start-time",
          "End Time must be greater than Start Time",
          function (value) {
            const { start_time } = this.parent;
            return start_time ? value > start_time : true;
          }
        ),
      code_start_date: yup.date().when("is_reusable_code", {
        is: true,
        then: () => yup.date().required("QR Code Start Date is required"),
        otherwise: () => yup.date().nullable().notRequired(),
      }),
      code_end_date: yup.date().when("is_reusable_code", {
        is: true,
        then: () => yup.date().required("QR Code End Date is required"),
        otherwise: () => yup.date().nullable().notRequired(),
      }),
      visitors: yup.array().of(
        yup.object().shape({
          name: yup.string().required("Name is required"),
          email: yup.string().email("Invalid Email").nullable(),
          phone_number: yup.string().when("country_code", {
            is: "+91", // Condition: when country_code is '+91'
            then: () =>
              yup
                .string()
                .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
                .nullable(),
            otherwise: () =>
              yup
                .string()
                .matches(/^[0-9]{6,15}$/, "Please Enter a valid Phone Number")
                .nullable(),
          }),
        })
      ),
    })
    .test(
      "one-must-be-their",
      "At least one Device must be there for this venue",
      function (value) {
        const { venue_id, device } = value;

        const venue = venueData?.find((item) => item?.value === venue_id);

        if (venue?.allow_electronic_device) {
          if (!Array.isArray(device) || device.length === 0) {
            return this.createError({
              path: "device",
              message: "At least one Device must be there for this venue",
            });
          }
        }

        return true;
      }
    );

  const generateQrCode = async (string) => {
    try {
      const url = await QRCode.toDataURL(string);
      const finalQr = base64ToFile(url);

      return finalQr;
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = async (values) => {
    setIsSaving(true);

    const barcodeValue = generateRandomString();

    const qr = await generateQrCode(barcodeValue);

    try {
      const res = await postFormData({
        endpoint: "Invite/createInvite",
        token: cookies.b_t,
        headerType: mode,
        data: {
          ...values,
          barcode_string: barcodeValue,
          qr_image: qr,
          invitee_name: capitalizeLetter(values.invitee_name),
          visitors:
            values?.visitors?.length > 0
              ? values?.visitors?.map((visitor) => ({
                  ...visitor,
                  name: capitalizeLetter(visitor.name), // Modify the name here as needed
                }))
              : null,
        },
      });

      if (res?.data) {
        if (mode === "Employee") {
          navigate("/dashboard/employee/invites");
        } else {
          navigate("/dashboard/business/invites");
        }

        toast.success("Invite Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    const qr = await generateQrCode(values.barcode_string);

    try {
      const res = await patchFormData({
        endpoint: "Invite/editInvite",
        token: cookies.b_t,
        data: {
          ...values,
          invitee_name: capitalizeLetter(values.invitee_name),
          qr_image: qr,
        },
        headerType: mode,
        params: { id: id },
      });

      if (res) {
        if (mode === "Employee") {
          navigate("/dashboard/employee/invites");
        } else {
          navigate("/dashboard/business/invites");
        }
        toast.success("Invite Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const compareDates = (date) => {
    if (date) {
      const givenDate = dayjs(date).startOf("day");
      const currentDate = dayjs().startOf("day");

      if (givenDate.isBefore(currentDate) || givenDate.isAfter(currentDate)) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
      innerRef={formikRef}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => (


        <>
          <PageHeader title={type === "add" ? "Create Invite" : "Edit Invite"}>
            <div className="d-flex gap-3">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (mode === "Employee") {
                    navigate("/dashboard/employee/invites");
                  } else {
                    navigate("/dashboard/business/invites");
                  }
                }}
              >
                Meeting Schedule
              </button>
              <BackBtn />
            </div>
          </PageHeader>

          <PageCard>
            <div className="row g-4">
              <InputLayout>
                <label htmlFor="venue">Select Venue</label>
                <SelectDropdown
                  placeholder="Select Venue"
                  data={venueData}
                  handleBlur={(e) => {
                    setFieldTouched("venue_id", true);
                    handleBlur(e);
                  }}
                  onChange={async (option) => {
                    fetchEmployeeData(option, setFieldValue);
                  }}
                  isError={touched.venue_id && errors.venue_id}
                  selectedValue={initialData?.venue_id}
                  isDisabled={type !== 'add'}
                />

                {touched.venue_id && errors.venue_id && (
                  <Error error={errors.venue_id} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="venue">Select Host</label>
                <SelectDropdown
                  placeholder="Select Host"
                  data={empData}
                  handleBlur={(e) => {
                    setFieldTouched("emp_id", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("emp_id", option);
                  }}
                  isError={touched.emp_id && errors.emp_id}
                  selectedValue={values?.emp_id}
                  isDisabled={
                    !isNotVenueAdmin(values?.venue_id) && mode === MODE_EMPLOYEE
                  }
                />

                {touched.emp_id && errors.emp_id && (
                  <Error error={errors.emp_id} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="venue">Invitee Type</label>
                <SelectDropdown
                  placeholder="Select Invitee Type"
                  data={inviteeType}
                  handleBlur={(e) => {
                    setFieldTouched("invitee_type", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("invitee_type", option);
                  }}
                  isError={touched.invitee_type && errors.invitee_type}
                  selectedValue={initialData?.invitee_type}
                />

                {touched.invitee_type && errors.invitee_type && (
                  <Error error={errors.invitee_type} />
                )}
              </InputLayout>
            </div>

            <div className="row g-4 mt-1">
              <InputLayout>
                <label htmlFor="venue">Invitee Name</label>
                <Field
                  type="text"
                  id="invitee_name"
                  name="invitee_name"
                  placeholder="Enter Invitee Name"
                  className={`${
                    touched.invitee_name && errors.invitee_name
                      ? "error-input"
                      : ""
                  }`}
                />
                {touched.invitee_name && errors.invitee_name && (
                  <Error error={errors.invitee_name} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Phone Number</label>
                <div className="d-flex gap-3">
                  <div className="col-4 col-lg-2">
                    <Field
                      type="text"
                      name="country_code"
                      className={`w-100 h-100 ${
                        touched.country_code && errors.country_code
                          ? "error-input"
                          : ""
                      }`}
                      disabled
                    />
                  </div>
                  <div
                    className="col-lg-10 pe-0 col-9"
                    style={{ flex: "auto" }}
                  >
                    <Field
                      type="text"
                      name="phone_number"
                      placeholder="Enter Phone Number"
                      className={`w-100 h-100 ${
                        touched.phone_number && errors.phone_number
                          ? "error-input"
                          : ""
                      }`}
                      onChange={(e) => {
                        let numericInput = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );

                        if (numericInput.length > 10) {
                          numericInput = numericInput.slice(0, 10);
                        }
                        setFieldValue("phone_number", numericInput);
                      }}
                    />
                  </div>
                </div>

                {touched.phone_number && errors.phone_number && (
                  <Error error={errors.phone_number || errors.phone_number} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="email">Email Id</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  className={`${
                    touched.email && errors.email ? "error-input" : ""
                  }`}
                />
                {touched.email && errors.email && (
                  <Error error={errors.email} />
                )}
              </InputLayout>
            </div>

            <div className="row g-4 mt-1">
              <InputLayout>
                <label htmlFor="company">Company</label>
                <Field
                  type="text"
                  id="company"
                  name="company"
                  placeholder="Enter Company Name"
                  className={`${
                    touched.company && errors.company ? "error-input" : ""
                  }`}
                />
                {touched.company && errors.company && (
                  <Error error={errors.company} />
                )}
              </InputLayout>
              {console.log('val', values)}
              <InputLayout>
                <label htmlFor="">Invitee Address</label>
                <Field
                  placeholder="Enter Invitee Address"
                  as="textarea"
                  id="address"
                  name="address"
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="">Remarks/ Special Instructions if Any</label>
                <Field
                  placeholder="Enter Remarks/ Special Instructions if Any"
                  as="textarea"
                  id="remarks"
                  name="remarks"
                />
              </InputLayout>
            </div>

            <div className="row g-4 mt-1">
              <InputLayout>
                <label htmlFor="">Select Date</label>
                <DateSelector
                  placeholder="Select Date"
                  onChange={(date) => setFieldValue("date", date)}
                  selectedDate={initialData?.date}
                  handleBlur={(e) => {
                    setFieldTouched("date", true);
                    handleBlur(e);
                  }}
                  disablePast
                  isError={touched.date && errors.date}
                />

                {touched.date && errors.date && <Error error={errors.date} />}
              </InputLayout>
              <InputLayout>
                <label htmlFor="">Meeting Start Time</label>

                <CustomTimePicker
                  placeholder="Pick Start Time"
                  handleBlur={(e) => {
                    setFieldTouched("start_time", true);
                    handleBlur(e);
                  }}
                  isError={values.date && errors.start_time}
                  onChange={(date) => setFieldValue("start_time", date)}
                  selectedTime={
                    initialData?.start_time || dayjs().format("HH:mm:ss")
                  }
                  // minTime={
                  //   compareDates(values?.date)
                  //     ? dayjs().format("HH:mm:ss")
                  //     : null
                  // }
                />
                {values.date && errors.start_time && (
                  <Error error={errors.start_time} />
                )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="">Meeting End Time</label>

                <CustomTimePicker
                  placeholder="Pick End Time"
                  handleBlur={(e) => {
                    setFieldTouched("end_time", true);
                    handleBlur(e);
                  }}
                  onChange={(date) => {
                    setFieldTouched("end_time", true);
                    setFieldValue("end_time", date);
                    validateForm({ ...values, end_time: date });
                  }}
                  isError={touched.end_time && errors.end_time}
                  selectedTime={initialData?.end_time}
                  // minTime={values.start_time}
                />
                {touched.end_time && errors.end_time && (
                  <Error error={errors.end_time} />
                )}
              </InputLayout>
            </div>

            <div className="row g-4 mt-1">
              <InputLayout>
                <label htmlFor="purpose">Purpose</label>
                <Field
                  type="text"
                  id="purpose"
                  name="purpose"
                  placeholder="Enter Purpose"
                  className={`${
                    touched.purpose && errors.purpose ? "error-input" : ""
                  }`}
                />
                {touched.purpose && errors.purpose && (
                  <Error error={errors.purpose} />
                )}
              </InputLayout>

              <InputLayout />

              <InputLayout />
            </div>

            <div className="additional__visitors">
              <FieldArray name="visitors">
                {({ push, remove }) => (
                  <>
                    {venueData?.filter(
                      (item) => item?.value === values.venue_id
                    )?.[0]?.allow_additional_visitor && (
                      <button
                        className="btn btn-link-primary px-0 mt-2"
                        style={{ textDecoration: "underline" }}
                        onClick={() =>
                          push({
                            id: null,
                            name: null,
                            email: null,
                            country_code: "+91",
                            phone_number: null,
                          })
                        }
                        disabled={values?.visitors?.length >= 5}
                      >
                        Add Additional Visitors
                      </button>
                    )}

                    {values?.visitors?.map((visitor, index) => (
                      <div key={index} id={index}>
                        <div className="field_array_detail">
                          <p className="p semi-light">VISITOR {index + 1}</p>
                        </div>

                        <div className="row g-4 mb-4">
                          <InputLayout>
                            <label htmlFor={`visitors[${index}].name`}>
                              Invitee Name
                            </label>
                            <Field
                              type="text"
                              id={`visitors[${index}].name`}
                              name={`visitors[${index}].name`}
                              placeholder="Enter Invitee Name"
                              className={
                                touched.visitors?.[index]?.name &&
                                errors.visitors?.[index]?.name
                                  ? "error-input"
                                  : ""
                              }
                            />
                            {touched.visitors?.[index]?.name &&
                              errors.visitors?.[index]?.name && (
                                <Error error={errors.visitors[index]?.name} />
                              )}
                          </InputLayout>

                          <InputLayout>
                            <label htmlFor={`visitors[${index}].phone_number`}>
                              Phone Number
                            </label>
                            <div className="d-flex gap-3">
                              <div className="col-4 col-lg-2">
                                <Field
                                  type="text"
                                  id={`visitors[${index}].country_code`}
                                  name={`visitors[${index}].country_code`}
                                  className={`w-100 h-100`}
                                  disabled
                                />
                              </div>
                              <div
                                className="col-lg-10 pe-0 col-9"
                                style={{ flex: "auto" }}
                              >
                                <Field
                                  type="text"
                                  id={`visitors[${index}].phone_number`}
                                  name={`visitors[${index}].phone_number`}
                                  placeholder="Enter Phone Number"
                                  className={`w-100 h-100 ${
                                    touched.visitors?.[index]?.phone_number &&
                                    errors.visitors?.[index]?.phone_number
                                      ? "error-input"
                                      : ""
                                  }`}
                                  onChange={(e) => {
                                    let numericInput = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );

                                    if (numericInput.length > 10) {
                                      numericInput = numericInput.slice(0, 10);
                                    }
                                    setFieldValue(
                                      `visitors[${index}].phone_number`,
                                      numericInput
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            {touched.visitors?.[index]?.phone_number &&
                              errors.visitors?.[index]?.phone_number && (
                                <Error
                                  error={errors.visitors[index]?.phone_number}
                                />
                              )}
                          </InputLayout>

                          <InputLayout>
                            <span
                              className="remove"
                              style={{ marginTop: "2.8rem" }}
                              onClick={() => {
                                // Push the id to remove_work array before removing the item
                                const removedId = values.visitors?.[index].id;

                                if (removedId) {
                                  setFieldValue("remove_visitors", [
                                    ...values.remove_visitors,
                                    removedId,
                                  ]);
                                }
                                remove(index);
                              }}
                            >
                              REMOVE
                            </span>
                          </InputLayout>
                        </div>
                        {/* </div> */}
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>

            {venueData?.filter((item) => item?.value === values.venue_id)?.[0]
              ?.allow_electronic_device && (
              <>
                <div className="row mt-4">
                  <label htmlFor="">Choose Devices</label>
                  <DeviceDataSElection
                    data={deviceList}
                    handleAddVenue={(devices) => {
                      setFieldValue("device", devices);
                    }}
                    selectedData={
                      initialData?.device?.length > 0
                        ? initialData?.device
                        : null
                    }
                  />
                </div>
              </>
            )}

            <div className="row mt-4">
              <div className={`checkbox_container`}>
                <label htmlFor={`is_reusable_code`}>Reuseable QR Code</label>
                <CheckboxWrapper
                  type="checkbox"
                  id="is_reusable_code"
                  name="is_reusable_code"
                  value={values.is_reusable_code}
                  checked={values.is_reusable_code}
                  onChange={() => {
                    setFieldValue("is_reusable_code", !values.is_reusable_code);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </div>
            </div>

            {values?.is_reusable_code && (
              <div className="row g-4 mt-1">
                <InputLayout>
                  <label htmlFor="">QR Code Start Date</label>
                  <DateSelector
                    placeholder="Select QR Start Date"
                    onChange={(date) => setFieldValue("code_start_date", date)}
                    selectedDate={initialData?.code_start_date}
                    handleBlur={(e) => {
                      setFieldTouched("code_start_date", true);
                      handleBlur(e);
                    }}
                    minDateData={values?.date}
                    maxDateData={values?.code_end_date}
                    isError={touched.code_start_date && errors.code_start_date}
                  />

                  {touched.date && errors.date && <Error error={errors.date} />}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="">QR Code End Date</label>
                  <DateSelector
                    placeholder="Select QR End Date"
                    onChange={(date) => setFieldValue("code_end_date", date)}
                    selectedDate={initialData?.code_end_date}
                    handleBlur={(e) => {
                      setFieldTouched("code_end_date", true);
                      handleBlur(e);
                    }}
                    disablePast
                    minDateData={values?.code_start_date}
                    isError={touched.code_end_date && errors.code_end_date}
                  />

                  {touched.code_end_date && errors.code_end_date && (
                    <Error error={errors.code_end_date} />
                  )}
                </InputLayout>

                <InputLayout></InputLayout>
              </div>
            )}

            <div className="d-flex gap-3 mt-4">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving}
                >
                  Add Invite
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving}
                >
                  Edit Invite
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default Add;
