import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import PasswordField from "../../../atoms/PasswordField";
import Error from "../../../atoms/Error";
import PasswordStrengthIndicator from "../../../organisms/passwordStrengthIndicator";
import { postData } from "../../../../services";
import * as yup from "yup";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";
import { LINK_MODE_EMPLOYEE } from "../../../../helpers/constants";

const PassForm = ({ uid, expiry, token, type , module}) => {
  const navigate = useNavigate();

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  // Form
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Please enter the correct format"
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const dataToSend = {
      password: values.password,
      user_id: uid,
      expiry: expiry,
      token: token,
    };

    const endpoint = module === LINK_MODE_EMPLOYEE ? 'EmployeeAuth/createPassword' : "BusinessAuth/createPassword"

    try {
      const { data: resultData, isLoading } = await postData({
        endpoint: endpoint,
        data: {},
        params: dataToSend,
      });

      if (resultData.status) {
        navigate("/auth/login");
        toast.success("Password Reset Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (error) {
      // console.log(dataToSend)
    }
    setSubmitting(false);
  };

  // const handleSubmit = () => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        touched,
        errors,
        values,
        isValid,
        dirty,
        handleBlur,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group mt-4">
            <PasswordField
              label="Password"
              id="password"
              name="password"
              value={values.password}
              onChange={(e) => setFieldValue("password", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              className={`${
                (touched.password || isSubmitting) && errors.password
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.password || isSubmitting) && errors.password && (
              <Error error={errors.password} />
            )}
          </div>

          <PasswordStrengthIndicator
            password={values.password}
            isPasswordValid={isPasswordValid}
            touched={touched.password}
          />

          <div className="form-group mt-4">
            <PasswordField
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter your Password"
              className={`${
                (touched.confirmPassword || isSubmitting) &&
                errors.confirmPassword
                  ? "error-input"
                  : ""
              }`}
            />
            {(touched.confirmPassword || isSubmitting) &&
              errors.confirmPassword && (
                <Error error={errors.confirmPassword} />
              )}
          </div>

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={!isValid || !dirty || isSubmitting}
            type="submit"
          >
            {type === "reset" ? "Reset" : "Create"} Password
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default PassForm;
