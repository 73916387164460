import { useEffect, useState } from "react";
import Close from "../../icons/Close";
import "./tag.scss"; // Import your CSS file

const TagInput = ({ placeholder, selectedTags, onChange, id, maxTags = Infinity, errors = [] , styles}) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (selectedTags && selectedTags.length > 0) {
      setTags(selectedTags);
    }
  }, [selectedTags]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "" && tags.length < maxTags) {
      e.preventDefault();
      const newTag = inputValue.trim();
      if (!tags.includes(newTag)) {
        setTags((prevTags) => {
          const updatedTags = [...prevTags, newTag];
          setInputValue("");
          onChange(updatedTags);
          return updatedTags;
        });
      } else {
        setInputValue("");
      }
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    onChange(newTags);
  };

  return (
    <div className="tag-input-container" key={id} style={styles}>
      <div className="tag-input">
        {tags.length > 0 &&
          tags.map((tag, index) => (
            <div key={index} className={`tag ${errors.includes(index) ? 'tag-error' : ''}`}>
              {tag}
              <button
                className="remove-button"
                onClick={() => handleTagRemove(tag)}
              >
                <Close className="tag-close-btn-container" />
              </button>
            </div>
          ))}
        {tags.length < maxTags && (
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            placeholder={placeholder || "Add tags..."}
            className="tag-input-feild"
          />
        )}
      </div>
    </div>
  );
};

export default TagInput;
