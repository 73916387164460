import React from "react";
import ValueLabelDisplay from "../../../../organisms/ValueLabelDisplay";

const View = ({ data }) => {
  return (
    <>
      <ValueLabelDisplay label="Location">{data?.venue_name}</ValueLabelDisplay>
      <ValueLabelDisplay label="Employee ID">{data?.employee_id}</ValueLabelDisplay>
      <ValueLabelDisplay label="Employee Name">{data?.name}</ValueLabelDisplay>
      <ValueLabelDisplay label="Phone Number">
        {" "}
        {data.phone_number && data.country_code} {data.phone_number}
      </ValueLabelDisplay>
      <ValueLabelDisplay label="Email">{data?.email}</ValueLabelDisplay>
      <ValueLabelDisplay label="Department">
        {data?.department}
      </ValueLabelDisplay>
      <ValueLabelDisplay label="Designation">
        {data?.designation}
      </ValueLabelDisplay>

      <ValueLabelDisplay label="Multiple Location Invite">
        <ul>
          {data?.multi_venues_name?.map((venue) => (
            <li key={venue}>{venue}</li>
          ))}
        </ul>
      </ValueLabelDisplay>

      <ValueLabelDisplay label="Vehicles">
        <ul>
          {data?.two_wheeler_number_1 &&  <li>Two Wheeler 1 -{data?.two_wheeler_number_1}</li> }
          {data?.two_wheeler_number_2 &&  <li>Two Wheeler 2 -{data?.two_wheeler_number_2}</li> }
          {data?.four_wheeler_number_1 &&  <li>Four Wheeler 1 -{data?.four_wheeler_number_1}</li> }
          {data?.four_wheeler_number_2 &&  <li>Four Wheeler 2 -{data?.four_wheeler_number_2}</li> }
        </ul>
      </ValueLabelDisplay>
    </>
  );
};

export default View;
