export const base64ToFile = (
  base64String,
  filename = "file",
  mimeType = "application/octet-stream"
) => {
  // Manually add the metadata if not present

  // Split the base64 string into metadata and base64 data
  const [metadata, base64] = base64String.split(",");
  const mime = metadata.match(/:(.*?);/)[1];

  // Decode base64 string
  const byteString = atob(base64);

  // Create an ArrayBuffer and a typed array view (Uint8Array)
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  // Write the bytes to the typed array
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  // Create a File object
  const file = new File([uint8Array], filename, { type: mime });

  return file;
};
