import { Formik, Form as FormikForm, FormikValues, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { batch, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import Error from "../../../atoms/Error";
import { postData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import { expitationTime } from "../../../../helpers/expirationTime";

const Form = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(-1);
  const [resendLoading, setResendLoading] = useState(false);
  const [cookie, setCookie] = useCookies([
    "buid",
    "role",
    "bun",
    "b_t",
    "bid",
    "profile",
    "both",
    "role",
    "va",
    "br",
  ]);

  const storedCountdownStartTime = localStorage.getItem("countdownStartTime");
  const initialResendTimer = storedCountdownStartTime
    ? Number(storedCountdownStartTime)
    : 30;

  const [resendTimer, setResendTimer] = useState(initialResendTimer);
  const [showResend, setShowResend] = useState(false);

  const startResendTimer = () => {
    setResendTimer(30);
    setShowResend(false);
  };

  const handleResendCompleted = () => {
    startResendTimer();
  };

  const initialValues = {
    otp: "",
  };

  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .length(6, "OTP is 6 digits long")
      .required("OTP is required"),
  });

  const handleResendClick = async () => {
    const endpoint =
      atob(cookie.role || "") === "Employee"
        ? "EmployeeAuth/resendOtp"
        : "BusinessAuth/resendOtp";
    try {
      // Set resendLoading to true when starting the resend process
      setResendLoading(true);
      dispatch(setLoading(true));
      const { data: resultData } = await postData({
        endpoint: endpoint,
        data: {},
        params: { user_id: cookie.buid },
      });

      if (resultData.status) {
        dispatch(setLoading(false));
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        handleResendCompleted();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setResendLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevResendTimer) => prevResendTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [resendTimer]);

  useEffect(() => {
    localStorage.setItem("countdownStartTime", resendTimer.toString());
  }, [resendTimer]);

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async (values) => {
    const endpoint =
      atob(cookie.role || "") === "Employee"
        ? "EmployeeAuth/verifyOtp"
        : "BusinessAuth/verifyOtp";

    const setCookies = (
      token,
      name,
      business_id,
      logo,
      venue_admin,
      is_expired
    ) => {
      setCookie("b_t", token, { path: "/", expires: expitationTime() });
      setCookie("bun", name, { path: "/", expires: expitationTime() });
      setCookie("bid", business_id, { path: "/", expires: expitationTime() });
      setCookie("profile", logo, { path: "/", expires: expitationTime() });
      setCookie("va", venue_admin, { path: "/", expires: expitationTime() });
      setCookie("isa", is_expired, { path: "/", expires: expitationTime() });
    };

    try {
      dispatch(setLoading(true));

      const dataToSend = { ...values, user_id: cookie.buid };
      const { data: resultData } = await postData({
        endpoint: endpoint,
        data: {},
        params: dataToSend,
      });

      dispatch(setLoading(false));

      if (resultData.status) {
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        if (atob(cookie.role || "") === "Employee") {
          setCookie("mode", cookie.role, {
            path: "/",
            expires: expitationTime(),
          });
          navigate("/dashboard/employee");
        } else {
          setCookie("mode", btoa("Business"), {
            path: "/",
            expires: expitationTime(),
          });
          navigate("/dashboard/business");
        }

        setCookies(
          resultData?.data?.token,
          btoa(resultData?.data?.user_name),
          resultData?.data?.business_id,
          btoa(resultData?.data?.logo),
          btoa(resultData?.data?.venue_admin),
          btoa(resultData?.data?.is_expired)
        );

        if (resultData?.data?.business_role) {
          setCookie("br", btoa(resultData?.data?.business_role), {
            path: "/",
            expires: expitationTime(),
          });
        }
        handleResendCompleted();
      } else {
        toast.error(resultData.message, { duration: 1000, style: errorStyles });
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
      toast.error("An error occurred. Please try again.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Verification Code</label>
            <Field
              type="text"
              name="otp"
              placeholder="Enter Verification Code"
              maxLength={6}
              pattern="[0-9]*"
              className={`${touched.otp && errors.otp ? "error-input" : ""}`}
              onInput={(e) => {
                e.preventDefault();
                e.target.value = parseInt(e.target.value) || "";
                e.target.value = e.target.value.toString().slice(0, 6);
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />

            {(touched.otp || isSubmitting) && errors.otp && (
              <Error error={errors.otp} />
            )}
          </div>

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={isSubmitting}
            type="submit"
          >
            Verify{" "}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <div className={`form_footer mt-4`}>
            <button
              className="btn btn-outline-dark"
              onClick={() => navigate(-1)}
              type="button"
            >
              Back
            </button>
            {resendTimer === 0 || showResend ? (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled={resendLoading} // Adjust the condition based on your logic
              >
                Resend
                {resendLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            ) : (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled // Adjust the condition based on your logic
              >
                {formatTimer(resendTimer)}
              </button>
            )}
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
