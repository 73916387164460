import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useCookies } from "react-cookie";
import ProfileSwap from "../../../../assets/images/profile.png";
import { useNavigate } from "react-router-dom";
import { getData } from "../../../../services";
import SelectDropdown from "../../Dropdown";
import { modesData, modesswitchData } from "../../../../helpers/staticData";
import { decodeCookieValue } from "../../../../helpers/cookieHelper";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";

const ToggleBtn = () => {
  const [cookies, setCookies] = useCookies(["role"]);
  const [bool, setBool] = useState(false);
  const [modeStatus, setModeStatus] = useState(1);
  const navigate = useNavigate();

  const role = decodeCookieValue(cookies.role);

  useEffect(() => {
    if (role === "Employee") {
      setModeStatus(1);
    } else {
      setModeStatus(2);
    }
  }, []);

  const handleModeSwitch = async (option) => {
    setModeStatus(option);

    const newMode = option === 2 ? "Business" : "Employee";
    if (role != newMode) {
      toast.success(`Switched to ${newMode} console`, {
        style: confimationStyles,
        duration: 900,
      });
    }

    // const getDetails = await getData({endpoint : , })

    setCookies("role", btoa(newMode), { path: "/" });
    setCookies("mode", btoa(newMode), { path: "/" });

    if (newMode === "Business") {
      navigate("/dashboard/business/home");
    } else {
      navigate("/dashboard/employee/home");
    }

    // Use a callback to ensure cookies are set before navigating
  };

  return (
    <>
      {/* <div
        className={styles.toggleBtn}
        onClick={() => {
          handleModeSwitch();
          setBool(true);
        }}
      >
        <span>Switch to {role === "Employee" ? "Business" : "Employee"}</span>
        <img src={ProfileSwap} alt="" width={16} height={16} />
      </div> */}

      {/* <div className="d-flex align-items-center">
        <label className={`mb-0 ${styles.label_switch}`}>Switch to:</label>
        <SelectDropdown
          data={modesData}
          selectedValue={modeStatus}
          type="dashboard"
          onChange={(option) => setModeStatus(option)}
        />
      </div> */}

      <div>
        <SelectDropdown
          data={modesData}
          selectedValue={modeStatus}
          type="dashboard"
          onChange={(option) => handleModeSwitch(option)}
        />
      </div>
    </>
  );
};

export default ToggleBtn;
