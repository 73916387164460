import React from "react";

const CheckOut = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <path d="m10,23c0,.553-.448,1-1,1h-4c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h6c2.757,0,5,2.243,5,5v2c0,.552-.448,1-1,1s-1-.448-1-1v-2c0-1.654-1.346-3-3-3h-6c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h4c.552,0,1,.447,1,1Zm-4-10h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0,4h-1c-.552,0-1,.447-1,1s.448,1,1,1h1c.552,0,1-.447,1-1s-.448-1-1-1Zm0-12h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm5,0h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm-5,4h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm5,0h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm13,8c0,3.859-3.14,7-7,7s-7-3.141-7-7,3.14-7,7-7,7,3.141,7,7Zm-2,0c0-2.757-2.243-5-5-5s-5,2.243-5,5,2.243,5,5,5,5-2.243,5-5Zm-3.501-2.742c-.251-.252-.629-.329-.957-.19-.328.136-.541.456-.541.812v1.121h-2c-.552,0-1,.447-1,1s.448,1,1,1h2v1.121c0,.355.213.676.541.812.109.045.223.067.336.067.229,0,.453-.089.621-.257l1.5-1.5c.685-.685.685-1.8,0-2.485l-1.5-1.5Z" />
    </svg>
  );
};

export default CheckOut;
