import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import Brand from "../../../assets/branding/main-logo.png";
import CircleAvatar from "../../atoms/CircleAvatar";
import styles from "./styles.module.scss";

import Home from "../../icons/Home";
import Venues from "../../icons/Venues";
import Plans from "../../icons/Plans";
import Payment from "../../icons/Payment";
import Configuration from "../../icons/Configuration";
import Logs from "../../icons/Logs";
import Employees from "../../icons/Employees";
import Activity from "../../icons/Activity";
import Invites from "../../icons/Invites";
import LogoutModal from "../../organisms/modals/LogoutModal";
import SupportRequests from "../../pages/dashboard/support-request";
import { decodeCookieValue } from "../../../helpers/cookieHelper";

import { useCookies } from "react-cookie";

import ToggleBtn from "./ToggleBtn";
import useScreenWidth from "../../../hooks/useScreenwidth";

const filterRoutes = (routes, role) => {
  if (role === "Viewer") {
    return Object.fromEntries(
      Object.entries(routes).filter(
        ([key]) =>
          key !== "configurations" && key !== "logs" && key !== "invites/add"
      )
    );
  }
  return routes;
};

const Navbar = ({ mode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["bun", "profile", "both", "role", "va", "br"]);
  const [isActive, setIsActive] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSupportRequest, setSupportRequest] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const hamburger = document.getElementById("hamburger_icon");
      const itemsContainer = document.getElementById("nav_items_container");

      if (
        hamburger &&
        !hamburger.contains(event.target) &&
        itemsContainer &&
        !itemsContainer.contains(event.target)
      ) {
        setIsActive(false);
      }
    };
    const handleScroll = () => {
      if (screenWidth < 1230) setIsActive(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [screenWidth]);

  const handleMenuOpen = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  const toggleActiveClass = () => {
    setIsActive(!isActive);
  };

  const removeActive = () => {
    setIsActive(false);
  };

  const isLinkActive = (path) => {
    const getBasePath = (url) => url.split("/")[3] || url; // Get the base path

    return location.pathname.startsWith(path) ||
      (mode === "Employee"
        ? path === "/dashboard/employee/home" &&
          location.pathname === "/dashboard/employee"
        : path === "/dashboard/business/home" &&
          location.pathname === "/dashboard/business") ||
      getBasePath(location.pathname) === getBasePath(path)
      ? styles.active_nav
      : "";
  };

  const routes = {
    // home: {
    //   name: "Home",
    //   icon: <Home />,
    // },
    venues: {
      name: "Venues",
      icon: <Venues />,
    },
    employees: {
      name: "Employees",
      icon: <Employees />,
    },
    activities: {
      name: "Activities & Reports",
      icon: <Activity />,
    },
    "invites/add": {
      name: "Invites",
      icon: <Invites />,
    },
    configurations: {
      name: "Configurations",
      icon: <Configuration />,
    },
  };

  const employeeOldRoutes = {
    home: {
      name: "Home",
      icon: <Home />,
    },
    "visitor-history": {
      name: "Visitor History",
      icon: <Activity />,
    },
    "invites/add": {
      name: "Invites",
      icon: <Invites />,
    },
  };

  const decodedValue = decodeCookieValue(cookies.va);

  let routeKeys = Object.keys(employeeOldRoutes);

  if (decodedValue === "true") {
    // Insert the 'employees' key at the second position
    routeKeys = [...routeKeys.slice(0, 1), "employees", ...routeKeys.slice(1)];
  }

  const employeeRoutes = routeKeys.reduce((acc, key) => {
    if (key === "employees") {
      acc[key] = {
        name: "Employees",
        icon: <Employees />,
      };
    } else {
      acc[key] = employeeOldRoutes[key];
    }
    return acc;
  }, {});

  const handleError = (e) => {
    e.target.src = Brand;
  };

  const filteredDashboardRoutes = filterRoutes(
    routes,
    decodeCookieValue(cookies.br)
  );

  return (
    <div className={styles.navbar_container}>
      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <nav className={styles.navbar}>
        {/* Logo */}

        <div className="d-flex gap-1 align-items-center">
          <div
            className={`${styles.hamburger} ${isActive ? styles.active : ""}`}
            id="hamburger_icon"
            onClick={toggleActiveClass}
          >
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
          </div>

          <div
            className={styles.icon}
            onClick={() =>
              mode === "Employee"
                ? navigate("/dashboard/employee")
                : navigate("/dashboard/business")
            }
          >
            <img
              src={decodeCookieValue(cookies.profile) || Brand}
              height={decodeCookieValue(cookies.profile) ? "32px" : "45px"}
              alt="Logo"
              onError={handleError}
            />
          </div>
        </div>

        <ul
          className={` ${styles.navMenu} ${isActive ? styles.active : ""}`}
          id="nav_items_container"
        >
          <>
            {Object.entries(
              decodeCookieValue(cookies.role) === "Employee"
                ? employeeRoutes
                : filteredDashboardRoutes
            ).map(([path, { name, icon }]) => (
              <li key={path} onClick={removeActive}>
                <NavLink
                  to={path}
                  className={`${styles.navLink} ${isLinkActive(
                    mode === "Employee"
                      ? `/dashboard/employee/${path}`
                      : `/dashboard/business/${path}`
                  )}`}
                >
                  {icon}
                  {name}
                </NavLink>
              </li>
            ))}
          </>
        </ul>

        <div className={styles.left_side}>
          {decodeCookieValue(cookies.both) === "true" ? <ToggleBtn /> : null}

          <div onClick={handleMenuOpen(setProfileAnchorEl)}>
            <CircleAvatar name={decodeCookieValue(cookies.bun)} />
          </div>
        </div>

        <Menu
          anchorEl={profileAnchorEl}
          open={Boolean(profileAnchorEl)}
          onClose={handleMenuClose(setProfileAnchorEl)}
          PaperProps={{
            style: {
              width: "200px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate(`/dashboard/${mode.toLowerCase()}/edit-profile`);
              setProfileAnchorEl(null);
            }}
          >
            Edit Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(
                `/dashboard/${mode.toLowerCase()}/edit-profile/change-password`
              );
              setProfileAnchorEl(null);
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSupportRequest(true);
              setProfileAnchorEl(null);
            }}
          >
            Support VisitorXys
          </MenuItem>
          <MenuItem
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setProfileAnchorEl(null);
            }}
          >
            <span style={{ color: "#BE0F00" }}>Logout</span>
          </MenuItem>
        </Menu>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose(setMenuAnchorEl)}
          PaperProps={{
            style: {
              width: "220px",
              borderRadius: "16px",
              marginTop: "20px",
              boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          {["resource", "library", "faqs", "taxonomy", "settings"].map(
            (menu) => (
              <MenuItem
                key={menu}
                onClick={() => {
                  navigate(`/dashboard/${menu}`);
                  setMenuAnchorEl(null);
                }}
              >
                {menu.charAt(0).toUpperCase() + menu.slice(1)}
              </MenuItem>
            )
          )}
        </Menu>
      </nav>
      {isSupportRequest && (
        <SupportRequests
          isFormActive={isSupportRequest}
          onClose={() => setSupportRequest(false)}
        />
      )}
    </div>
  );
};

export default Navbar;
