import React, { useState, useMemo, useCallback, useEffect } from "react";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import Tab from "./Tab";

import TabStrip from "../../../molecules/tabstrip";
import { Box, InputLabel } from "@mui/material";
import SelectDropdown from "../../../molecules/Dropdown";
import InputLayout from "../../../atoms/InputLayout";
import DateRangePicker from "../../../organisms/dateRangePicker";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import View from "../../../icons/View";

import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { setLoading } from "../../../../store/slices/loaderSlice";
import { useCookies } from "react-cookie";

import { getData, patchData } from "../../../../services";
import Pagination from "../../../molecules/Pagination";
import ImageModal from "../../../organisms/modals/ImageModal";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import {
  getEmployeeList,
  getVenueList,
  getVisitorList,
} from "../../../../services/basicGets";
import { activityStatusInvites, statusData, statusInvites } from "../../../../helpers/staticData";
import { downloadReport } from "../../../../services/downloadService";
import MultiselectFilter from "../../../organisms/MultiselectFilter";
import ArrowDown from "../../../icons/ArrowDown";
import CustomButton from "../../../atoms/CustomButton";
import Upload from "../../../icons/Upload";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../assets/styles/toast";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";
import ImageAndInitialViewer from "../../../molecules/ImageAndInitialViewer";
import moment from "moment";
import Modal from "../../../organisms/modals/Modal";
import ViewDetails from "./View";
import TerminalCheckout from "../../../icons/terminalCheckout";
import { decodeCookieValue } from "../../../../helpers/cookieHelper";
import { adjustToUTC } from "../../../organisms/dateRangePicker";

import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
} from "date-fns";

const Plans = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["b_t", "bid", "br"]);
  const role = decodeCookieValue(cookies.br);

  const defaultToday = new Date();
  const [date, setDate] = useState({
    start_date: adjustToUTC(
      addHours(startOfDay(startOfMonth(defaultToday)), 12)
    ),
    end_date: adjustToUTC(addHours(startOfDay(endOfMonth(defaultToday)), 12)),
  });

  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [image, setImage] = useState({
    isActive: null,
    image: null,
  });
  const [venueData, setVenueData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [vistorsData, setVisitorsData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    venues: null,
    hosts: null,
    status: null,
    visitors: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [resetAll, setResetAll] = useState(false);
  const [isVenueOpen, setIsVenueOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isHostOpen, setIsHostOpen] = useState(false);
  const [isVisitorOpen, setIsVisitorOpen] = useState(false);
  const [applyFilters, setApplyFilters] = useState(false);
  const [modalState, setModalState] = useState({
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });

  const [terminalModal, setTerminalModal] = useState({
    isTerminalModalOpen: false,
    modalId: "",
    name: "",
  });

  useEffect(() => {
    (async () => {
      const empDet = await getEmployeeList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setEmployeeData(empDet?.data);
      const visData = await getVisitorList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      let valueLabelForm = visData.map((item) => ({
        value: item,
        label: item,
      }));
      setVisitorsData(valueLabelForm);
      const resVenue = await getVenueList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setVenueData(resVenue);
    })();
  }, []);

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "BusinessReport/getReportOfActivityofInvite",
      token: cookies.t,
      fileName: "ActivityReport",
      params: {
        business_id: cookies.bid,
        start_date: date.start_date,
        end_date: date.end_date,
        venues:
          selectedFilters?.venues?.length > 0
            ? JSON.stringify(selectedFilters.venues)
            : null,
        status:
          selectedFilters?.status?.length > 0
            ? JSON.stringify(selectedFilters.status)
            : JSON.stringify(["Completed", "Ongoing"]),
        visitors:
          selectedFilters?.visitors?.length > 0
            ? JSON.stringify(selectedFilters?.visitors)
            : null,
        hosts:
          selectedFilters?.hosts?.length > 0
            ? JSON.stringify(selectedFilters?.hosts)
            : null,
      },
    });
    // console.log("RES", res);
    if (res) {
      toast.success("Activities & Reports Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  const getActivities = useCallback(async () => {
    // console.log(selectedFilters.venues, selectedFilters.status);
    try {
      const res = await getData({
        endpoint: "Invite/getInvitesForActivity",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
          business_id: cookies.bid,
          start_date: date.start_date,
          end_date: date.end_date,
          venues:
            selectedFilters?.venues?.length > 0
              ? JSON.stringify(selectedFilters.venues)
              : null,
          status:
            selectedFilters?.status?.length > 0
              ? JSON.stringify(selectedFilters.status)
              : JSON.stringify(["Completed", "Ongoing"]),
          visitors:
            selectedFilters?.visitors?.length > 0
              ? JSON.stringify(selectedFilters?.visitors)
              : null,
          hosts:
            selectedFilters?.hosts?.length > 0
              ? JSON.stringify(selectedFilters?.hosts)
              : null,
        },
        token: cookies.b_t,
      });
      if (res) {
        setTableData(res);
        // console.log("res --> ", res);
      }
    } catch (err) {}
  }, [
    searchedValue,
    cookies.b_t,
    currentPage,
    itemsPerPage,
    applyFilters,
    resetAll,
    selectedFilters,
    date,
  ]);

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.invite_id,
      data: data,
      name: data?.visitor_name,
    }));
  };

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  const handleTerminalModal = (data) => {
    setTerminalModal((prevState) => ({
      ...prevState,
      isTerminalModalOpen: true,
      modalId: data.invite_id,
      name: data?.visitor_name,
    }));
  };

  const columns = [
    {
      field: "photo",
      headerName: "Photo",
      minWidth: 70, // Minimum width for photo column
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              onClick={() => {
                if (params.row.check_in_photo) {
                  setImage({
                    isActive: true,
                    image: params.row.check_in_photo,
                  });
                } else {
                  return;
                }
              }}
            >
              <ImageAndInitialViewer
                image={params.row.check_in_photo}
                name={params.row.visitor_name}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "venue_name",
      headerName: "Venue",
      minWidth: 150, // Minimum width for venue column
      sortable: true,
    },
    {
      field: "visitor_name",
      headerName: "Visitor Name",
      minWidth: 200, // Minimum width for visitor name column
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span className="ms-2 text-ecp" title={params.row.visitor_name}>
              {params.row.visitor_name}
            </span>
            <span className="ms-1">
              {params.row?.addition_visitor?.length > 0
                ? `+${params.row.addition_visitor?.length}`
                : ""}
            </span>
          </>
        );
      },
    },
    {
      field: "host_name",
      headerName: "Host Name",
      minWidth: 150, // Minimum width for host name column
      sortable: true,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   minWidth: 200, // Minimum width for email column
    //   sortable: true,
    // },
    // {
    //   field: "contact_number",
    //   headerName: "Contact Number",
    //   minWidth: 150, // Minimum width for contact number column
    //   sortable: true,
    // },
    {
      field: "contact",
      headerName: "Contact Details",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-column">
            {params.row.contact_number && (
              <span>{params.row.contact_number}</span>
            )}
            {params.row.email && params.row.email !== "null" && (
              <span>{params.row.email}</span>
            )}
            <span></span>
          </div>
        );
      },
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150, // Minimum width for company column
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 200, // Minimum width for address column
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 200, // Minimum width for address column
    },
    {
      field: "date",
      headerName: "Date",
      minWidth: 150, // Minimum width for address column
      renderCell: (params) => {
        return (
          <>
            <span> {moment(params.row?.date).format("DD/MM/YYYY")}</span>
          </>
        );
      },
    },
    {
      field: "check_in_time",
      headerName: "Check In Time",
      minWidth: 200, // Minimum width for address column
      renderCell: (params) => {
        return (
          <>
            <span>
              {" "}
              {params.row?.check_in_time &&
                moment(params.row?.check_in_time).format("DD/MM/YYYY hh:mm a")}
            </span>
          </>
        );
      },
    },
    {
      field: "check_out_time",
      headerName: "Check Out Time",
      minWidth: 200, // Minimum width for address column
      renderCell: (params) => {
        return (
          <>
            <span>
              {" "}
              {params.row?.check_out_time &&
                moment(params.row?.check_out_time).format("DD/MM/YYYY hh:mm a")}
            </span>
          </>
        );
      },
    },
    {
      field: "stay_duration",
      headerName: "Stay Duration",
      minWidth: 150, // Minimum width for address column
      renderCell: (params) => {
        const time = params.row?.stay_duration
          ? moment(params.row?.stay_duration, "HH:mm:ss")
          : null;
        return (
          <>
            <span> {time && time.format("HH:mm")}</span>
          </>
        );
      },
    },
    {
      field: "overstayed_hours",
      headerName: "Overstayed Hours",
      minWidth: 200, // Minimum width for address column
      renderCell: (params) => {
        const time = params.row?.overstayed_hours
          ? moment(params.row?.overstayed_hours, "HH:mm:ss")
          : null;
        return (
          <>
            <span> {time && time.format("HH:mm")}</span>
          </>
        );
      },
    },
    {
      headerName: "Action",
      minWidth: 100, // Minimum width for action column
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={() => handleView(params.row)}>
              <View />
            </div>
            {params.row.status == "Ongoing" && (
              <div onClick={() => handleTerminalModal(params.row)}>
                <TerminalCheckout />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleTerminalCheckout = async () => {
    try {
      await patchData({
        endpoint: "Invite/forceCheckout",
        token: cookies.b_t,
        params: { invite_id: terminalModal?.modalId },
      });
      getActivities();
      setTerminalModal((prevState) => ({
        ...prevState,
        isTerminalModalOpen: false,
      }));
      toast.success("Terminal Chekout Succesfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } catch (error) {
      toast.error("An Error Occured While Checkout", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {/* {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : ( */}
        <NoContentCard
          title="No Activities & Report added yet!"
          type="Company"
          icon={<NoContentIcon />}
          small={true}
        />
        {/* )} */}
      </>
    );
  });

  return (
    <>
      <PageHeader title="Activities & Reports">
        <div className="d-flex gap-3">
          {role !== "Viewer" && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              iconRequired
              icon={<Upload />}
            />
          )}

          <DateRangePicker type="header-filter" setDate={setDate} />
        </div>
      </PageHeader>
      <PageCard classes="pt-3">
        <div className="mb-3 mt-2 d-flex gap-3 flex-nowrap">
          <div className="filter-container align-items-center">
            <Search
              onSearchEmit={(value) => {
                setSearchedValue(value);
                if (value.length > 2) {
                  if (currentPage !== 1) setCurrentPage(1);
                }
              }}
              value={searchedValue}
              variant="outline"
              placeholder="Search By Phone Number & Visitor Name"
            />

            <MultiselectFilter
              title="Select Venue"
              icon={<ArrowDown />}
              type="filter"
              data={venueData}
              open={isVenueOpen}
              setOpen={setIsVenueOpen}
              handleFilter={(filter) => {
                if (filter != null) {
                  setSelectedFilters({
                    ...selectedFilters,
                    venues: [...filter],
                  });
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    venues: null,
                  });
                }
                setResetAll(false);
              }}
              reset={resetAll}
            />

            <MultiselectFilter
              title="Select Status"
              icon={<ArrowDown />}
              type="filter"
              data={activityStatusInvites}
              open={isStatusOpen}
              setOpen={setIsStatusOpen}
              handleFilter={(filter) => {
                if (filter != null) {
                  setSelectedFilters({
                    ...selectedFilters,
                    status: [...filter],
                  });
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    status: null,
                  });
                }
                setResetAll(false);
              }}
              reset={resetAll}
            />

            <MultiselectFilter
              title="Select Host"
              icon={<ArrowDown />}
              type="filter"
              data={employeeData}
              open={isHostOpen}
              setOpen={setIsHostOpen}
              handleFilter={(filter) => {
                if (filter != null) {
                  setSelectedFilters({
                    ...selectedFilters,
                    hosts: [...filter],
                  });
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    hosts: null,
                  });
                }
                setResetAll(false);
              }}
              reset={resetAll}
            />

            {/* <MultiselectFilter
              title="Select Visitor"
              icon={<ArrowDown />}
              type="filter"
              data={vistorsData}
              open={isVisitorOpen}
              setOpen={setIsVisitorOpen}
              handleFilter={(filter) => {
                if (filter != null) {
                  setSelectedFilters({
                    ...selectedFilters,
                    visitors: [...filter],
                  });
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    visitors: null,
                  });
                }
                setResetAll(false);
              }}
              reset={resetAll}
            /> */}

          </div>
          <div className="filter-btns">
          <button
                id="reset"
                className="btn btn-outline-primary flex-1"
                onClick={() => {
                  setSelectedFilters({
                    venues: null,
                    hosts: null,
                    status: null,
                    visitors: null,
                  });
                  setSearchedValue('')
                  setResetAll(!resetAll);
                }}
              >
                Reset
              </button>
          </div>
        </div>

        <div className="table_with_scroll">
          <CustomDatagrid
            columns={columns}
            rows={tableData?.data || []}
            CustomNoRowsOverlay={CustomNoRowsOverlay}
            getRowId={(row) => row.invite_id}
            height={() => 70}
          />
        </div>

        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}

        {image?.isActive && (
          <ImageModal
            isActive={image?.isActive}
            setIsActive={setImage}
            image={image?.image}
          />
        )}
      </PageCard>

      {terminalModal.isTerminalModalOpen && (
        <DeleteModal
          name={terminalModal.name}
          id={terminalModal.modalId}
          isActive={terminalModal.isTerminalModalOpen}
          onClose={() =>
            setTerminalModal((prevState) => ({
              ...prevState,
              isTerminalModalOpen: false,
            }))
          }
          title="Activity"
          type="Checkout"
          description={`Are you sure do you want checkout ${terminalModal.name} from the Activity list.`}
          onClick={handleTerminalCheckout}
        />
      )}

      {modalState.isViewModalOpen && (
        <Modal
          name={`${modalState.name}`}
          title={`${modalState.name}`}
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          width="640px"
        >
          <ViewDetails data={modalState.data} />
        </Modal>
      )}
    </>
  );
};

export default Plans;
