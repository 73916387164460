import React, { useState } from "react";
import ImageAndInitialViewer from "../../../../molecules/ImageAndInitialViewer";
import moment from "moment";
import ImageModal from "../../../../organisms/modals/ImageModal";
import { convertUtcToLocalTime } from "../../../../../helpers/convertUtcToLocalTime";

const CheckInCard = ({ item, type }) => {
  const [image, setImage] = useState({
    isActive: null,
    image: null,
  });

  // console.log('zone',Intl.DateTimeFormat().resolvedOptions().timeZone)

  const handleImage = (image) => {

    if (image) {
      setImage({
        isActive: true,
        image: image,
      });
    }
  };

  return (
    <>
      <div className="dashboard__info__modal checked__in__card">
        <div
          onClick={() => handleImage(item?.check_in_photo)}
          className={item?.check_in_photo ? "cursor-pointer" : ""}
        >
          <ImageAndInitialViewer image={item.check_in_photo} name={item.name} />
        </div>
        <div className="details">
          <h6
            className="list-group-item-heading"
            onclick="showEmployeeDetail(947)"
          >
            {item.name}{" "}
            {item?.additional_visitor_count > 0 &&
              `+${item.additional_visitor_count}`}
            <span class="font-small-3 float-right dark-blue">
              {" "}
              {type === "checkin"
                ? item?.check_in_time
                  ? convertUtcToLocalTime(item?.check_in_time)
                  : null
                : item?.check_out_time
                ? `${convertUtcToLocalTime(item?.check_in_time)} / ${convertUtcToLocalTime(item?.check_out_time)}`
                : null}
            </span>
          </h6>
          <p className="list-group-item-text text-muted ">
            {item.contact_no}
            <span className="float-right dark-blue">
              {moment(item?.date).format("DD/MM/YYYY")}
            </span>{" "}
          </p>
        </div>
      </div>
      <hr style={{ borderTop: "1px solid #efefef", opacity: 1 }} />

      {image?.isActive && (
        <ImageModal
          isActive={image?.isActive}
          setIsActive={setImage}
          image={image?.image}
        />
      )}
    </>
  );
};

export default CheckInCard;
