const QrCode = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="14"
      height="14"
    >
      <path d="M0,11H11V0H0V11ZM2,2h7v7H2V2Zm2,2h3v3H4V4ZM24,0H13V11h11V0Zm-2,9h-7V2h7v7Zm-2-2h-3V4h3v3ZM0,24H11V13H0v11ZM2,15h7v7H2v-7Zm2,2h3v3H4v-3Zm13-4v4h-4v-4h4Zm0,4h3v3h-3v-3Zm-4,3h4v4h-4v-4Zm11-7v4h-4v-4h4Z" />
    </svg>
  );
};
export default QrCode;
