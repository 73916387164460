import React from "react";
import ValueLabelDisplay from "../../../../organisms/ValueLabelDisplay";
import ImageAndInitialViewer from "../../../../molecules/ImageAndInitialViewer";
import moment from "moment";

const ViewDetails = ({ data }) => {
  const parseStayDuration = (stayDuration) => {
    if (stayDuration) {
      return moment(stayDuration, "HH:mm:ss");
    } else {
      return null;
    }
  };
  return (
    <>
      {data?.check_in_photo && (
        <ValueLabelDisplay label="Check In Photo">
          <ImageAndInitialViewer image={data.check_in_photo} />
        </ValueLabelDisplay>
      )}
      <ValueLabelDisplay label={"Venue Name"}>
        {data.venue_name}
      </ValueLabelDisplay>

      <ValueLabelDisplay label="Invitee Name">
        {data.visitor_name}
      </ValueLabelDisplay>
      <ValueLabelDisplay label="Purpose">
        {data.purpose}
      </ValueLabelDisplay>
      <ValueLabelDisplay label={"Contact No"}>
        {data?.contact_number}
      </ValueLabelDisplay>
      <ValueLabelDisplay label={"Email"}>{data.email}</ValueLabelDisplay>
      <ValueLabelDisplay label={"Company"}>{data.company}</ValueLabelDisplay>
      <ValueLabelDisplay label={"Address"}>{data.address}</ValueLabelDisplay>
      {data?.check_in_time && (
        <ValueLabelDisplay label={"Check In Time"}>
          {" "}
          {moment(data?.check_in_time).format("DD/MM/YYYY hh:mm a")}
        </ValueLabelDisplay>
      )}
      {data?.check_out_time && (
        <ValueLabelDisplay label={"Check Out Time"}>
          {" "}
          {moment(data?.check_out_time).format("DD/MM/YYYY hh:mm a")}

        </ValueLabelDisplay>
      )}
      {data?.stay_duration && (
        <ValueLabelDisplay label={"Stay Duration"}>
          {" "}
          {parseStayDuration(data?.stay_duration) &&
            parseStayDuration(data?.stay_duration).format("HH:mm")}
        </ValueLabelDisplay>
      )}
      {data?.overstayed_hours && (
        <ValueLabelDisplay label={"Overstayed Hours"}>
          {" "}
          {parseStayDuration(data?.overstayed_hours) &&
            parseStayDuration(data?.overstayed_hours).format("HH:mm")}
        </ValueLabelDisplay>
      )}
      {data && data?.addition_visitor.length > 0 && (
        <>
          <div className=" mb-3 fw-bold fs-6 ">Additional Visitors</div>
          {data.addition_visitor?.map((item, index) => (
            <div
              className="d-flex w-100 mb-3 fw-bold align-items-center"
              key={index}
            >
              <div className="w-40 d-flex gap-2 justify-content-between">
                <div
                  style={{
                    color: "#6f767e",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  Visitor {index + 1}
                </div>{" "}
                :
              </div>
              <div className="d-flex w-50 align-items-center">
                <div
                  className="w-60 ms-4 fw-bold text-wrap"
                  style={{
                    color: "#1a1d1f",
                    fontWeight: "500",
                    fontSize: "14px",
                    overflowWrap: "anywhere",
                  }}
                >
                  {item.visitor_name}
                </div>
                <div
                  className="w-40 ms-4 fw-bold d-flex gap-1"
                  style={{
                    color: "#1a1d1f",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}
                >
                  {item?.phone_number && item.country_code && <div>{item.country_code}</div>}
                  {item?.phone_number}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default ViewDetails;
