import React, { useState, useEffect, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../../../store/slices/loaderSlice";
import {
  getData,
  patchData,
  postData,
  postFormData,
} from "../../../../services";
import View from "../../../icons/View";
import Edit from "../../../icons/Edit";
import Delete from "../../../icons/Delete";
import More from "../../../icons/More";
import VerticalMenu from "../../../icons/VerticalMenu";
import { useDelete } from "../../../../hooks/useDelete";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import CustomButton from "../../../atoms/CustomButton";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import Pagination from "../../../molecules/Pagination";
import toast from "react-hot-toast";
import { errorStyles } from "../../../molecules/Dropdown/dropdown";
import DateRangePicker from "../../../organisms/dateRangePicker";
import { Menu, MenuItem } from "@mui/material";
import { confimationStyles } from "../../../../assets/styles/toast";
import { statusInvites } from "../../../../helpers/staticData";

import moment from "moment";
import FilterComponent from "../../../organisms/filters/FilterComponent";
import { getVenueList } from "../../../../services/basicGets";
import useScreenWidth from "../../../../hooks/useScreenwidth";
import addMonths from "date-fns/addMonths";
import { getEmployeeVenueList } from "../../../../services/basicGets";
import QRCode from "qrcode";
import {
  startOfMonth,
  endOfMonth,
  addDays,
  addHours,
  startOfDay,
} from "date-fns";
import QrCode from "../../../icons/qrcode";
import "./styles.scss";
import { base64ToFile } from "../../../../helpers/base64ToFile";
import { set } from "rsuite/esm/internals/utils/date";

const Plans = ({ mode }) => {
  const defaultToday = new Date();
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const [cookies] = useCookies(["b_t", "bid", "buid"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [date, setDate] = useState({
    start_date: addHours(startOfDay(startOfMonth(defaultToday)), 12),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)), 12),
  });
  const [filters, setFilters] = useState({});
  const [venueData, setVenueData] = useState([]);
  const [modalState, setModalState] = useState({
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });
  const [modalStateQR, setModalStateQR] = useState({
    isQRModalOpen: false,
    data: null,
  });
  const [anchorEl, setAnchorEl] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  // Function to handle opening menu for a specific row
  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  // Function to close menu for a specific row
  const handleCloseMenu = (index) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      let res;
      if (mode === "Employee") {
        res = await getEmployeeVenueList({
          emp_id: cookies.buid,
          token: cookies.b_t,
        });
      } else {
        res = await getVenueList({
          business_id: cookies.bid,
          token: cookies.b_t,
        });
      }
      setVenueData(res);
    })();
  }, []);

  const getAllInvites = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "Invite/getAllInvitesByBusinessId",
        params: {
          search_query: searchedValue,
          page: currentPage,
          page_limit: itemsPerPage,
          business_id: cookies.bid,
          venues:
            filters?.Venues?.length > 0
              ? JSON.stringify(filters?.Venues)
              : null,
          status:
            filters?.Status?.length > 0
              ? JSON.stringify(filters?.Status)
              : null,
          start_date: date.start_date,
          end_date: date.end_date,
        },
        token: cookies.b_t,
      });

      if (res) {
        setTableData(res);
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.b_t, currentPage, itemsPerPage, filters, date]);

  const handleUpdate = async (id, status) => {
    try {
      const res = await patchData({
        endpoint: "Invite/editStatus",
        params: {
          id: id,
          status: status,
        },
        headerType: mode,
      });

      if (res) {
        toast.success("Status Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        getAllInvites();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An Error Occured", { duration: 1000, style: errorStyles });
    }
  };
  const generateQrCode = async (string) => {
    try {
      const url = await QRCode.toDataURL(string);
      const finalQr = base64ToFile(url);

      return finalQr;
    } catch (err) {
      console.error(err);
    }
  };

  const handleResend = async (value) => {
    const qr = await generateQrCode(value.bar_code);

    try {
      const res = await postFormData({
        endpoint: "Invite/resendInvitation",
        params: {
          invite_id: value.id,
        },
        data: {
          qr_image: qr,
        },
        headerType: mode,
        token: cookies.b_t,
      });

      if (res) {
        toast.success("Invitation Resended Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        getAllInvites();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An Error Occured", { duration: 1000, style: errorStyles });
    }
  };

  const handleQRCode = async (id) => {
    setModalStateQR({
      isQRModalOpen: true,
      data: id,
    });
  };

  const handleDeletionQR = async (id) => {
    try {
      const res = await postData({
        endpoint: "Invite/revokePermanentQR",
        params: {
          invite_id: id,
        },
        headerType: mode,
        token: cookies.b_t,
      });

      if (res) {
        toast.success("QR Revoked successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        getAllInvites();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("An Error Occured", { duration: 1000, style: errorStyles });
    } finally {
      setModalStateQR({
        isQRModalOpen: false,
        data: null,
      });
    }
  };

  useEffect(() => {
    getAllInvites();
  }, [getAllInvites]);
  const columns = [
    {
      field: "venue_name",
      headerName: "Venue Name",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "invitee_name",
      headerName: "Invitee Name",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "host_name",
      headerName: "Host Name",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {params.row.phone_number && params.row.country_code}{" "}
            {params.row.phone_number}
          </span>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "address",
      headerName: "Address",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "company",
      headerName: "Company",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      sortable: true,
    },
    {
      field: "start_time",
      headerName: "Time For Meeting",
      ...(screenWidth < 1230 ? { width: 200 } : { flex: 1.5 }),
      sortable: true,
      renderCell: (params) => {
        return (
          <span>
            {moment.utc(params.row?.date).format("DD/MM/YYYY")}{" "}
            {params?.row.start_time
              ? moment
                  .utc(params?.row.start_time, "HH:mm:ss")
                  .local()
                  .format("hh:mm A")
              : "--"}
          </span>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      ...(screenWidth < 1230 ? { width: 120 } : { flex: 0.8 }),
      renderCell: (params) => {
        const status = {
          Confirmed: "text-success",
          Cancelled: "text-error",
          Unheld: "text-grey",
          Completed: "text-error",
          Ongoing: "text-warning",
        };
        return (
          <>
            <span className={`${status[params.row.status]} fw-bold`}>
              {params?.row?.status}
            </span>
          </>
        );
      },
    },
    {
      headerName: "Action",
      ...(screenWidth < 1230 ? { width: 130 } : { flex: 0.9 }),
      renderCell: (params) => {
        return (
          <>
            {params.row.status !== "Completed" &&
              params.row.status !== "Ongoing" && (
                <div className="table-icons-container">
                  {/* Edit icon */}
                  <div onClick={() => navigate(`edit/${params.row.id}`)}>
                    <Edit />
                  </div>

                  {/* Delete icon */}
                  <div onClick={() => handleDeleteModal(params.row)}>
                    <Delete />
                  </div>

                  {/* Vertical menu icon */}

                  {params.row.status !== "Unheld" && (
                    <div
                      onClick={(event) => handleStatusMenu(event, params.row)}
                    >
                      <VerticalMenu />
                    </div>
                  )}

                  {/* Menu with status options */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedRow === params.row}
                    onClose={() => handleCloseMenu()}
                    PaperProps={{
                      style: {
                        width: "160px",
                        borderRadius: "16px",
                        marginTop: "10px", // Add margin-top to create space
                        boxShadow: "0px 4px 14px 0px rgba(0, 0, 0, 0.015)", // Aesthetically pleasing box shadow
                        border: "1px solid #efefef",
                        fontWeight: "500",
                        fontSize: "14px", // Corrected typo in fontSize
                      },
                    }}
                  >
                    {params.row.status !== "Confirmed" && (
                      <>
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            handleUpdate(params.row.id, "Confirmed");
                          }}
                        >
                          Confirmed
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            handleResend(params.row, "Confirmed");
                          }}
                        >
                          Resend Invite
                        </MenuItem>
                      </>
                    )}
                    {params.row.status !== "Cancelled" && (
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          handleUpdate(params.row.id, "Cancelled");
                        }}
                      >
                        <span style={{ color: "#BE0F00" }}>Cancelled</span>
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            {params.row.status === "Completed" &&
              params.row.is_permanent_qr_active && (
                <div
                  onClick={() => handleQRCode(params.row.id)}
                  style={{ marginLeft: "10px" }}
                  title="Revoke QR"
                  className={`cursor-pointer`}
                >
                  <QrCode />
                </div>
              )}
          </>
        );
      },
    },
  ];
  const deleteInvite = useDelete(mode);

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };
  const handleDeletion = async () => {
    try {
      await deleteInvite("Invite/deleteInvite", "Invite", {
        id: modalState.modalId,
      });
      getAllInvites();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      toast.error("An Error Occured While Deleting", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Invites Created Yet!"
            subtitle="Click on Create Invite button to create a invite"
            tag="Create Invite"
            handleClick={() => navigate("add")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    Venues: venueData,
    Status: statusInvites,
  };
  return (
    <>
      <PageHeader title="Meeting Schedule">
        <DateRangePicker type="header-filter" setDate={setDate} />
      </PageHeader>
      <PageCard>
        <div className="d-flex w-100 mb-2 justify-content-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
            placeholder="Search By Invitee Name"
          />

          <div className="d-flex gap-2">
            <FilterComponent
              filterData={filtersData}
              onFilterChange={(filters) => {
                setFilters(filters);
              }}
            />

            <CustomButton
              text="Create Invite"
              type="btn-primary"
              handleClick={() => navigate("add")}
            />
          </div>
          {/* <DateRangePickerShortCuts setDate={setDate} /> */}
        </div>

        <CustomDatagrid
          getRowId={(row) => row.id}
          rows={tableData?.data || []}
          columns={columns}
          CustomNoRowsOverlay={CustomNoRowsOverlay}
        />
        {tableData?.total_record > 25 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={tableData?.total_record}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Invite"
            onClick={handleDeletion}
          />
        )}
        {modalStateQR.isQRModalOpen && (
          <DeleteModal
            name={modalState.name}
            isActive={modalStateQR.isQRModalOpen}
            onClose={() =>
              setModalStateQR((prevState) => ({
                ...prevState,
                isQRModalOpen: false,
                data: null,
              }))
            }
            description="Visitors will no longer be able to check in or check out using a QR code or invite code. Are you sure you want to revoke it?"
            title="Revoke QR"
            type=""
            onClick={() => handleDeletionQR(modalStateQR.data)}
          />
        )}
      </PageCard>
    </>
  );
};

export default Plans;
