import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../../services";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";
import TagInput from "../../../../../molecules/TagInput";
import InputLayout from "../../../../../atoms/InputLayout";
import CustomTimePicker from "../../../../../organisms/TimePicker";
import dayjs from "dayjs";
import Error from "../../../../../atoms/Error";
import PageHeader from "../../../../../atoms/PageHeader";

const Settings = ({ initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["b_t",'bid']);
  const [data, setData] = useState();

  const settingsData = async () => {
    const res = await getData({endpoint :'BusinessConfiguration/getGeneralSetting', params : {business_id : cookies.bid}, token : cookies.b_t})
    setData(res)
  }

  useEffect(() => {
    settingsData();
  },[])

  const initialData = {
    emails: data?.emails || null,
    time: data?.time || null,
  };

  const validationSchema = Yup.object({
    time: Yup.string().required("Email Sending Time is Required"),
    emails: Yup.array()
      .of(Yup.string().required("Each Department name is required").email(true))
      .min(1, "At least one Email is required")
      .required("At least one Email is required"),
  });

  const handleSubmit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "BusinessConfiguration/updateGeneralSetting",
        token: cookies.b_t,
        data: {...values, business_id : cookies.bid},
      });

      if (res) {
        toast.success("Settings Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });

        settingsData();
      } 
    } catch (error) {
    } 
  };

  const compareDates = (date) => {
    if (date) {
      const givenDate = dayjs(date).startOf("day");
      const currentDate = dayjs().startOf("day");

      if (givenDate.isBefore(currentDate) || givenDate.isAfter(currentDate)) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <Form>
          <PageHeader title="General Settings" type="small" className="mb-0" />

          <InputLayout>
            <label htmlFor="name">Daily Report Emails</label>
            {console.log("err", errors)}
            <TagInput
              placeholder="Enter Emails"
              onChange={(values) => {
                setFieldValue("emails", values);
              }}
              maxTags={5}
              errors={
                Array.isArray(errors?.emails) &&
                errors.emails.length > 0
                  ? errors.emails
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1)
                  : []
              }
              selectedTags={values.emails}
              styles={{ minHeight: "48px" }}
            />
          </InputLayout>

          <div className="row">
            <div className="col-3 mt-3">
              <InputLayout>
                <label htmlFor="">Email Sending Time</label>

                <CustomTimePicker
                  placeholder="Email Sending Time"
                  handleBlur={(e) => {
                    setFieldTouched("time", true);
                    handleBlur(e);
                  }}
                  isError={touched.time && errors.time}
                  onChange={(date) => setFieldValue("time", date)}
                  selectedTime={initialData?.time}
                />
                {touched.time && errors.time && (
                  <Error error={errors.time} />
                )}
              </InputLayout>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary"
              disabled={!isValid}
              onClick={() => handleSubmit(values)}
            >
              Update Settings
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Settings;
