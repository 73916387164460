import React from "react";

const Visit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <path d="m13,23c0,.552-.448,1-1,1h-7c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h6c2.757,0,5,2.243,5,5v5c0,.552-.448,1-1,1s-1-.448-1-1v-5c0-1.654-1.346-3-3-3h-6c-1.654,0-3,1.346-3,3v14c0,1.654,1.346,3,3,3h7c.552,0,1,.448,1,1Zm-7-10h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm5,0h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm-5,4h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm5,0h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1ZM6,5h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm5,0h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm-5,4h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm5,0h-1c-.552,0-1,.448-1,1s.448,1,1,1h1c.552,0,1-.448,1-1s-.448-1-1-1Zm11.389,12.389l-1.84,1.8c-.565.553-1.307.829-2.049.829s-1.484-.276-2.049-.829l-1.833-1.792c-2.152-2.152-2.152-5.642-.008-7.786,1.039-1.039,2.42-1.611,3.889-1.611s2.85.572,3.889,1.61h0c2.145,2.145,2.145,5.634,0,7.779Zm-1.414-6.364c-.662-.661-1.541-1.025-2.475-1.025s-1.814.364-2.475,1.025c-1.365,1.364-1.365,3.585,0,4.95l1.825,1.784c.358.351.942.351,1.301,0l1.833-1.792c1.357-1.357,1.357-3.578-.008-4.942Zm-2.475.969c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Z" />
    </svg>
  );
};

export default Visit;
