import React from 'react'
import ValueLabelDisplay from '../../../../organisms/ValueLabelDisplay'
import { light } from '@mui/material/styles/createPalette'

const View = ({data}) => {
  return (
    <>
    <ValueLabelDisplay label='Venue Name'>{data?.name}</ValueLabelDisplay>
    <ValueLabelDisplay label='Description'>{data?.description}</ValueLabelDisplay>
    <ValueLabelDisplay label='Address'>{data?.address}, {data?.city}, {data?.state}, {data?.country}</ValueLabelDisplay>
    <ValueLabelDisplay label='Venue Admins'>
      <ul>
        {data?.admin.map((data) => <li>{data}</li>)}
      </ul>
    </ValueLabelDisplay>
    </>
  )
}

export default View