import React, { useState } from "react";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import InputLayout from "../../../atoms/InputLayout";
import { countryCodeData } from "../../../../helpers/countryCodes";
import SelectDropdown from "../../../molecules/Dropdown";
import "./styles.scss";
import ValueLabelDisplay from "../../../organisms/ValueLabelDisplay";

const Logs = () => {
  const [code, setCode] = useState(null);
  const [phone, setPhone] = useState(null);
  const [showLogs, setShowLogs] = useState(false);

  const handleLogs = () => {
    setShowLogs((prev) => !prev);
  };

  const data = [
    {
      name: "Venue 1",
      info: {
        total_check_in: 10,
        total_check_out: 100,
        checkout_midnight: 10,
      },
    },
    {
      name: "venue 2",
      info: {
        total_check_in: 10,
        total_check_out: 17,
        checkout_24: 10,
      },
    },
  ];
  return (
    <>
      <PageHeader title="Visitor Logs" />
      <PageCard>
        <div className="row g-4">
          <InputLayout>
            <label htmlFor="phone_number">Enter Phone Number</label>
            <div className="d-flex gap-3">
              <div className="col-4">
                <SelectDropdown
                  data={countryCodeData}
                  placeholder="Code"
                  onChange={(option) => {
                    setCode(option);
                  }}
                  selectedValue={code}
                />
              </div>
              <div className="col-8 pe-0" style={{ flex: "auto" }}>
                <input
                  type="text"
                  name="phone_number"
                  className={`w-100 h-100`}
                  placeholder="Enter Phone Number"
                  onChange={(e) => {
                    let numericInput = e.target.value.replace(/[^0-9]/g, "");
                    if (code === "+91") {
                      // Enforce a maximum length of 10 digits
                      if (numericInput.length > 10) {
                        numericInput = numericInput.slice(0, 10);
                      }
                      setPhone(numericInput);
                    } else {
                      setPhone(numericInput);
                    }
                  }}
                />
              </div>
              <button className="btn btn-primary" onClick={() => handleLogs()} disabled={!code ||  !phone}>
                {showLogs ? "Hide Logs" : "Show Logs"}
              </button>
            </div>
          </InputLayout>
          <InputLayout></InputLayout>
        </div>

        {showLogs && (
          <div className="log-div-container ">
            {data?.map((item) => (
              <div className="log-div">
                <h4 className="mb-3">{item.name}</h4>
                <ValueLabelDisplay label="Total Check In">
                  {item?.info?.total_check_in}
                </ValueLabelDisplay>
                <ValueLabelDisplay label="Total Check Out">
                  {item?.info?.total_check_out}
                </ValueLabelDisplay>
                {item?.info?.checkout_midnight && (
                  <ValueLabelDisplay label="Check Out At Midnight">
                    {item?.info?.checkout_midnight}
                  </ValueLabelDisplay>
                )}
                {item?.info?.checkout_24 && (
                  <ValueLabelDisplay label="Check Out At 24 hrs">
                    {item?.info?.checkout_24}
                  </ValueLabelDisplay>
                )}
              </div>
            ))}
          </div>
        )}
      </PageCard>
    </>
  );
};

export default Logs;
