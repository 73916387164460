import React, { useCallback, useState, useMemo, useEffect } from "react";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../atoms/NoContentCard";
import NoContentIcon from "../../../../icons/NocontentIcon";
import Pagination from "../../../../molecules/Pagination";
import ImageAndInitialViewer from "../../../../molecules/ImageAndInitialViewer";
import View from "../../../../icons/View";
import Modal from "../../../../organisms/modals/Modal";
import moment from "moment";
import ImageModal from "../../../../organisms/modals/ImageModal";
import VisitorLogsDetails from "../Views/Current";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import { convertUtcToLocalTime } from "../../../../../helpers/convertUtcToLocalTime";

const MeetInformation = ({
  type,
  data,
  currentPage,
  itemsPerPage,
  setCurrentPage,
  setItemsPerPage,
}) => {
  const screenWidth = useScreenWidth();

  const [tableData, setTableData] = useState({});
  const [modalState, setModalState] = useState({
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });
  const [image, setImage] = useState({
    isActive: null,
    image: null,
  });

  useEffect(() => {
    const element = document.querySelector(".MuiDataGrid-virtualScroller");
    if (element) {
      element.scrollLeft = 0;
    }
  }, [type]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      data: data,
    }));
  };

  const columnConfigurations = {
    current: [
      {
        field: "check_in_photo",
        headerName: "Photo",
        ...(screenWidth < 1230 ? { width: 90 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <>
              <div
                onClick={() =>
                  setImage({ isActive: true, image: params.row.check_in_photo })
                }
                className="cursor-pointer"
              >
                <ImageAndInitialViewer image={params.row.check_in_photo} />
              </div>
            </>
          );
        },
      },
      {
        field: "name",
        headerName: "Invitee Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <>
              <span className="ms-2 text-ecp" title={params.row.name}>
                {params.row.name}
              </span>
              <span className="ms-1">
                {params.row?.addition_visitor?.length > 0
                  ? `+${params.row.addition_visitor?.length}`
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        field: "contact_deatils",
        headerName: "Contact Details",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div className="d-flex flex-column">
              {params.row.contact_no && <span>{params.row.contact_no}</span>}
              {params.row.email && params.row.email !== "null" && (
                <span>{params.row.email}</span>
              )}
              <span></span>
            </div>
          );
        },
      },
      {
        field: "employee_name",
        headerName: "Host Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "check_in",
        headerName: "Check In Time",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div>
              {params.row.check_in_time &&
                convertUtcToLocalTime(params.row.check_in_time)}
            </div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Action",
        ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <div className="table-icons-container">
              <div onClick={() => handleView(params.row)}>
                <View />
              </div>
            </div>
          );
        },
      },
      // Add other column configurations for current visitors
    ],
    upcoming: [
      {
        field: "name",
        headerName: "Invitee Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1.5 }),
        renderCell: (params) => {
          return (
            <>
              <ImageAndInitialViewer name={params.row.name} />
              <span className="ms-2 text-ecp" title={params.row.name}>
                {params.row.name}
              </span>
              <span className="ms-1">
                {params.row?.addition_visitor?.length > 0
                  ? `+${params.row.addition_visitor?.length}`
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        field: "contact_deatils",
        headerName: "Contact Details",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div className="d-flex flex-column">
              {params.row.contact_no && <span>{params.row.contact_no}</span>}
              {params.row.email && params.row.email !== "null" && (
                <span>{params.row.email}</span>
              )}
              <span></span>
            </div>
          );
        },
      },
      {
        field: "employee_name",
        headerName: "Host Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "venue_name",
        headerName: "Venue Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "appointmentDate",
        headerName: "Time For Meeting",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <>
              <span>
                {" "}
                {moment(params.row?.date).format("DD/MM/YYYY")}{" "}
                {moment
                  .utc(params.row.start_time, "HH:mm:ss")
                  .local()
                  .format("hh:mm A")}
              </span>
            </>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "actions",
        headerName: "Action",
        ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <div className="table-icons-container">
              <div onClick={() => handleView(params.row)}>
                <View />
              </div>
            </div>
          );
        },
      },
      // Add other column configurations for upcoming visitors
    ],
    history: [
      {
        field: "check_in_photo",
        headerName: "Photo",
        ...(screenWidth < 1230 ? { width: 90 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <div
              onClick={() =>
                setImage({ isActive: true, image: params.row.check_in_photo })
              }
              className="cursor-pointer"
            >
              <ImageAndInitialViewer image={params.row.check_in_photo} />
            </div>
          );
        },
      },
      {
        field: "name",
        headerName: "Visitor Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <>
              <span className="ms-2 text-ecp" title={params.row.name}>
                {params.row.name}
              </span>
              <span className="ms-1">
                {params.row?.addition_visitor?.length > 0
                  ? `+${params.row.addition_visitor?.length}`
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        field: "contact_deatils",
        headerName: "Contact Details",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div className="d-flex flex-column">
              {params.row.contact_no && (
                <span title={params.row.contact_no}>
                  {params.row.contact_no}
                </span>
              )}
              {params.row.email && params.row.email !== "null" && (
                <span title={params.row.email}>{params.row.email}</span>
              )}
              <span></span>
            </div>
          );
        },
      },
      {
        field: "company",
        headerName: "Company",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "venue_name",
        headerName: "Venue Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "employee_name",
        headerName: "Host Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },

      {
        field: "visitDate",
        headerName: "Visit Date",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return <span> {moment(params.row?.date).format("DD/MM/YYYY")}</span>;
        },
      },
      {
        field: "check_in",
        headerName: "Check In Time",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div>
              {params.row.check_in_time &&
                convertUtcToLocalTime(params.row.check_in_time)}
            </div>
          );
        },
      },
      {
        field: "check_out",
        headerName: "Check Out Time",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div>
              {params.row.check_out_time &&
                convertUtcToLocalTime(params.row.check_out_time)}
            </div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Action",
        ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <div className="table-icons-container">
              <div onClick={() => handleView(params.row)}>
                <View />
              </div>
            </div>
          );
        },
      },
      // Add other column configurations for visitor history
    ],
  };

  const columns = useMemo(() => {
    return columnConfigurations[type] || [];
  }, [type]);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard
          title="No Record Found"
          type="Company"
          icon={<NoContentIcon />}
          small={true}
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  return (
    <>
      <CustomDatagrid
        getRowId={(row) => row.invite_id}
        rows={tableData?.data || []}
        columns={columns}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="25em"
        height={() => 70}
        hoverColor="fff"
      />

      {tableData?.total_record > 25 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.total_record}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalState.isViewModalOpen && (
        <Modal
          name={`Invitee Details`}
          title={`Invitee Details`}
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          width="640px"
        >
          {type === "current" ? (
            <VisitorLogsDetails data={modalState.data} />
          ) : type === "upcoming" ? (
            <VisitorLogsDetails data={modalState.data} />
          ) : (
            <VisitorLogsDetails data={modalState.data} />
          )}
        </Modal>
      )}

      {image?.isActive && (
        <ImageModal
          isActive={image?.isActive}
          setIsActive={setImage}
          image={image?.image}
        />
      )}
    </>
  );
};

export default MeetInformation;
