import React from 'react'
import Form from './Form'

const SignUp = () => {
  return (
    <>
    <div className="h1-i mb-4">Create a new account</div>
    <Form/>
    </>

  )
}

export default SignUp