import React, { useState } from "react";
import Modal from "../../../organisms/modals/Modal";
import Form from "./Form";

const SupportRequests = ({ isFormActive = true, onClose }) => {
  return (
    <Modal
      isActive={isFormActive}
      title="Support Request"
      onClose={() => onClose()}
      width="550px"
    >
      <Form onClose={() => onClose()}/>
    </Modal>
  );
};

export default SupportRequests;
