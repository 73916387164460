import React, { useState } from "react";
import { useAuthenticated } from "../hooks/useAuthenticated";
import { useLocation, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { decodeCookieValue } from "../helpers/cookieHelper";
import { useMemo } from "react";

const AuthGuard = (props) => {

  const [cookies] = useCookies(["isa",'mode']);
  const decodedMode = decodeCookieValue(cookies.mode)
  const given_mode = props.mode;


  const isExpired = atob(cookies.isa || '');

  const { children, allowed } = props;
  const auth = useAuthenticated();

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState();

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }
  if (isExpired == 'true' && location.pathname != "/dashboard") {
    return <Navigate to="/dashboard" />;
  }

  if (decodedMode === given_mode && given_mode === 'Employee') {
    return <EmployeeAuthGuard>{props.children}</EmployeeAuthGuard>;
  }

  if (decodedMode === given_mode && given_mode === 'Business') {
    return <BusinessAuthGaurd allowedRole={allowed}>{props.children}</BusinessAuthGaurd>;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};


const EmployeeAuthGuard = (props) => {
  const [cookies] = useCookies(["va"]);

  const is_admin = (decodeCookieValue(cookies.va) === "true") ? true : false;

  const routePermissions = useMemo(
    () => [
      { route: "/dashboard/employee/employees", permission: is_admin },
      { route: "/dashboard/employee/employees/add", permission: is_admin },
      { route: "/dashboard/employee/employees/edit", permission: is_admin },
    ],
    [cookies.va]
  );

  const { children } = props;
  const auth = useAuthenticated();
  // const auth = true
  const location = useLocation();

  const [requestedLocation, setRequestedLocation] = useState();

  const hasPermission = useMemo(() => {
    
    let currentRoute = routePermissions.find(rp => rp.route === location.pathname);

    if (!currentRoute) {
      currentRoute = routePermissions.find(rp => {
        const regex = new RegExp(`^${rp.route?.replace(/\/:.*$/, '/[^/]+')}$`);
        return regex.test(location.pathname);
      });
    }
  
    // If still no match, check for a prefix match
    if (!currentRoute) {
      currentRoute = routePermissions
        .filter(rp => location.pathname?.startsWith(rp.route))
        .sort((a, b) => b.route.length - a.route.length)[0];
    }
  
    return currentRoute ? currentRoute.permission : true;
  }, [location.pathname, routePermissions]);

  if (!hasPermission) {
    return <Navigate to="/404" />; // Redirect to a "no access" page
  }

  if (!auth) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth" />;
  }
  return <>{children}</>;
};

const BusinessAuthGaurd = (props) => {
  const { allowedRole, children } = props;

  const isAuthenticated = useAuthenticated();

  const [cookies] = useCookies(['br'])
  const decodeRole = decodeCookieValue(cookies.br);

  if (isAuthenticated && allowedRole?.includes(decodeRole.toLowerCase())) {
    return children;
  }

  return <Navigate to="/auth/login" replace />;
}

export default AuthGuard;
