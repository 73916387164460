import React from 'react'
import ValueLabelDisplay from '../../../../../organisms/ValueLabelDisplay'

const TerminalView = ({data}) => {
  return (
    <>
    <ValueLabelDisplay label='Account Id'>{data?.account_id}</ValueLabelDisplay>
    <ValueLabelDisplay label='Terminal Id'>{data?.authorization_id}</ValueLabelDisplay>
    <ValueLabelDisplay label='Terminal Password'>{data?.authorization_code}</ValueLabelDisplay>
    <ValueLabelDisplay label='Pin'>{data?.pin}</ValueLabelDisplay>

    </>
  )
}

export default TerminalView