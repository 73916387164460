import React from "react";
import ImageAndInitialViewer from "../../../../molecules/ImageAndInitialViewer";
import moment from "moment";
import "./styles.scss";

const UpcomingCard = ({ item }) => {
  return (
    <>
      <div className="dashboard__info__modal upcoming__card">
        <ImageAndInitialViewer image={null} name={item.name} />
        <div className="details">
          <h6
            className="list-group-item-heading"
            onclick="showEmployeeDetail(947)"
          >
            {item.name}{"  "}
            {item?.additional_visitor_count > 0 &&
              `+${item.additional_visitor_count}`}
            <span class="font-small-3 float-right dark-blue">
              {" "}
              {moment.utc(item.start_time, "HH:mm:ss").local().format("hh:mm A")}
            </span>
          </h6>
          <p className="list-group-item-text text-muted ">
            {item.contact_no}
            <span className="float-right dark-blue">
              {moment(item?.date).format("DD/MM/YYYY")}
            </span>{" "}
          </p>
          <p className="list-group-item-text text-muted">
            Host: {item.employee_name}
          </p>
          <p className="list-group-item-text text-muted">
            Company: {item.company}
          </p>
        </div>
      </div>
      <hr style={{ borderTop: "1px solid #efefef", opacity: 1 }} />
    </>
  );
};

export default UpcomingCard;
