import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { patchData, postData } from "../../../../../../services";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";
import {
  getVenueList,
  getEmployeeList,
  getParticularEmployeeVenueList,
} from "../../../../../../services/basicGets";

const UserForm = ({ onClose, initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["b_t"]);

  const [venueData, setVenueData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    fetchVenueList(initialValues?.emp_id)
  },[initialValues])

  useEffect(() => {
    (async () => {
      const resEmployee = await getEmployeeList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setEmployeeData(resEmployee?.data);
    })();
  }, []);

  const fetchVenueList = async (emp_id) => {
    const resVenue = await getParticularEmployeeVenueList({
      business_id: cookies.bid,
      emp_id: emp_id,
      token: cookies.b_t,
    });
    setVenueData(resVenue);
  };

  const initialData = {
    emp_id: initialValues?.emp_id || null,
    venue_ids:
      initialValues?.venues?.map((venue) => venue.id) ||
      venueData
        .filter((venue) => venue.alread_added)
        .map((venue) => venue.value),
  };

  const validationSchema = Yup.object({
    emp_id: Yup.string().required("Employee is required"),
    venue_ids: Yup.array().required("Venue is required"),
  });

  const handleSubmit = async (values) => {
    const res = await postData({
      endpoint: "BusinessConfiguration/addVenueAdmin",
      token: cookies.b_t,
      data: values,
    });

    if (res?.data?.status) {
      toast.success("Venue Admin Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
      onClose();
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "BusinessConfiguration/editVenueAdmin",
        token: cookies.b_t,
        data: values,
        params: { id: initialValues?.id },
      });

      if (res.status) {
        toast.success("Venue Admin Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
      }) => (
        <Form>
          <div className="form-group">
            <label htmlFor="name">Employees</label>
            <SelectDropdown
              data={employeeData}
              placeholder="Select Employee"
              onChange={(option) => {
                setFieldValue("emp_id", option);
                fetchVenueList(option);
              }}
              selectedValue={initialData?.emp_id}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="role_id">Venues</label>
            <SelectDropdown
              data={venueData}
              placeholder="Select Venues"
              onChange={(option) => {
                setFieldValue("venue_ids", option);
              }}
              multiSelect
              selectedValue={initialData?.venue_ids}
              onClear={() => {
                setFieldValue("venue_ids", null);
              }}
            />
          </div>

          <div className="d-flex justify-content-end mt-3">
            {initialValues ? (
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid}
                onClick={() => handleEdit(values)}
              >
                Update
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary w-20"
                disabled={!isValid}
              >
                Add
              </button>
            )}
          </div>

        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
