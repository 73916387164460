import React from "react";

const Activity = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="m6.5,16H1C.448,16,0,15.552,0,15s.448-1,1-1h4.965l1.703-2.555c.197-.296.542-.473.894-.443.356.022.673.232.833.551l2.229,4.459,1.044-1.566c.186-.278.498-.445.832-.445h4.5c.552,0,1,.448,1,1s-.448,1-1,1h-3.965l-1.703,2.555c-.186.279-.499.445-.832.445-.021,0-.042,0-.062-.002-.356-.022-.673-.232-.833-.551l-2.229-4.459-1.044,1.566c-.186.278-.498.445-.832.445Zm15.5-5.515v8.515c0,2.757-2.243,5-5,5H7c-2.757,0-5-2.243-5-5,0-.552.448-1,1-1s1,.448,1,1c0,1.654,1.346,3,3,3h10c1.654,0,3-1.346,3-3v-8.515c0-.163-.008-.325-.023-.485h-4.977c-1.654,0-3-1.346-3-3V2.023c-.16-.015-.322-.023-.485-.023h-4.515c-1.654,0-3,1.346-3,3v6c0,.552-.448,1-1,1s-1-.448-1-1v-6C2,2.243,4.243,0,7,0h4.515c1.87,0,3.627.728,4.95,2.05l3.485,3.485c1.322,1.322,2.05,3.08,2.05,4.95Zm-2.659-2.485c-.218-.379-.487-.733-.805-1.05l-3.485-3.485c-.318-.318-.671-.587-1.05-.805v4.341c0,.551.449,1,1,1h4.341Z" />
    </svg>
  );
};

export default Activity;
