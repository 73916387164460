import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Form from "./Form";

const Login = () => {
  const navigate = useNavigate();
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e) => {
    e.preventDefault();
    navigate(0);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", onButtonEvent);
    return () => {
      window.removeEventListener("popstate", onButtonEvent);
    };
  }, []);
  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      <div className="h1-i mb-4">Login to VisitorXsys</div>
      <Form />
      {/* <div className='d-flex justify-content-center mt-3 gap-1 fw-bold'><span className='text-dark'>Don’t have an account? </span> <span className='text-primary fw-bolder cursor-pointer' onClick={() => navigate('/auth/signup')}> SignUp</span></div> */}
    </>
  );
};

export default Login;
